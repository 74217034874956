import React from "react";

import "../../../assets/scss/step4Style.scss";

const Step4 = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            edit: false
        }

        if(this.props.state !== null){
            this.state = this.props.state;
        }
    }
    isValid(){
        return true;
    }
    render() {
        const { edit } = this.state;
        const { authorized } = this.props;
        
        return (
            <div className="sd-step-four-content">
                <div className="sd-step4-note">
                    <h3>You're almost there!</h3>
                    {
                        authorized ?
                            <p>Click on any of the steps above to review or edit anything you've entered. If you're ready, click the button below to { edit ? 'update' : 'create' } your Forever Hug!</p>
                        :
                            <p>Click on any of the steps above to review or edit anything you've entered. If you’re ready, click the button below to sign-in to create your Forever Hug!</p>
                    }
                </div>
            </div>
        );
    }
}

export default Step4;