import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Button, DialogActions } from "@mui/material";
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../../components/Loader';

import "../../../assets/scss/emailModalStyle.scss";

class EmailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            hug: props.hug,
            emailSent: false
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            hug: props.hug,
        });
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    getRecipentEmail(recipient){
        return(
            <p>{"your "+recipient.relationship+" "+recipient.first_name+" at "+recipient.email}</p>
        )
    }
    getRecipentEmails(hug){
        let hugRecipients = hug.recipients;

        let recipientEmails = "";
        hugRecipients.map((item, key) => {
            recipientEmails += "your "+item.relationship+" "+item.first_name+" at "+item.email;

            if (key + 1 !== hugRecipients.length) {
                recipientEmails += ", "
            }
            
            return null;
        });

        return recipientEmails;
    }
    onSendEmail(e){
        const { hug } = this.state;

        this.sendRecipientsEmail(hug);
    }
    sendRecipientsEmail(hug){
        let requestData = {
            id: hug.id,
        };

        this.setState({
            sending: true
        })

        const source = axios.CancelToken.source();
        Api.sendRecipientsEmail(requestData, source).then(data => {
            this.setState({
                response: data,
                sending: false,
                emailSent: true
            });

            this.props.onSendEmail(data.hug);
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                sending: false,
            });
        });
    }
    render() {
        const { sending, hug, emailSent } = this.state;

        return (
            <>
                <Dialog
                    maxWidth="lg"
                    fullWidth={true}
                    open={this.props.open}
                    className="sd-email-modal"
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        {
                            emailSent === false ?
                                <h5 className={"modal-title"}>Well done!</h5>
                            :
                                <></>
                        }
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-email-modal-content">
                            {
                                emailSent ?
                                    <p>Your hug has been sent!</p>
                                :
                                    <>
                                        <p>{"You're one click away from sharing your hug with your loved one. By clicking send, your hug will be sent to..."}</p>
                                        {
                                            hug.recipients.map(recipient => {
                                                return this.getRecipentEmail(recipient)
                                            })
                                        }
                                        <p>{"Thank you for spreading love with forever hugs!"}</p>
                                    </>
                            }
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        {
                            sending ?
                                <LoaderComponent />
                            : emailSent ?
                                <Button className={"button radius-8"} onClick={() => this.props.onClose(false)}>Close</Button>
                            :
                                <>
                                    <Button className={"button radius-8"} onClick={() => this.props.onAddRecipient()}>Add Recipient</Button>
                                    <Button className={"button radius-8"} onClick={(e) => this.onSendEmail(e)}>Send</Button>
                                </>
                        }
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}
export default connect(mapStateToProps)(EmailModal);