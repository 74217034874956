import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Accordion, AccordionSummary, AccordionDetails  } from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import "../../../assets/scss/inspirationMessageModalStyle.scss";

class InspirationMessageModal extends React.Component {
    render() {
        return (
            <>
                <Dialog
                    className="sd-inspirational-message-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        <h5 className={"modal-title"}>Inspirational Forever Hug Messages to Spark your Creativity</h5>
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-inspirational-message-content">
                            <p>A Forever Hug should come from the heart and be a reflection of the unique relationship and bond between you and your loved one.</p>
                            <p>Below are some tips to help you get started.</p>
                            <ul>
                                <li>Express gratitude for specific ways your loved one has impacted your life.</li>
                                <li>Share a personal experience of your time together.</li>
                                <li>Speak to your loved one in a way that feels natural and authentic to your unique relationship.</li>
                                <li>Provide humor, especially if it reflects an inside joke.</li>
                                <li>Share a favorite quote, poem, or song lyrics that hold special meaning.</li>
                                <li>Provide life advice and hopes and dreams for the future.</li>
                                <li>Use a nickname or term of endearment that you commonly use for your loved one.</li>
                            </ul>
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <p>To a Child</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>My sweet boy, I am so proud of the young man you are becoming.  You will always be my [insert nickname]  You are one of the bravest kids I know.  As you grow, work hard in all you do and stay humble.  Go on adventures, try new things, and remember how blessed you are.  Put your family first and God at the center. You are meant for great things so get out there and leave your mark on this world.  I love you.  [Mom or Dad]</p>
                                        <p>[insert name if daughter] you are beautiful inside and out.  I love you from the bottom of my heart and nothing will ever change that.  I love how you are growing to become a kind and compassionate person.  Don’t lose your passion for reading.  I remember every night reading your favorite stories over and over again.  You are ENOUGH.  You warm my heart with your smile, creativity and empathy for others. Share your light with the world!  You will do amazing things, I believe in you.  Hugs and Kisses.  [Mom or Dad]</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <p>To a Grandchild</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>[insert name(s) of grandchild], you have brought so much joy and laughter to my life.  We don’t always get to see each other but I want you to know you have a special place in my heart.  Be strong and courageous.  Things in life won’t always go your way so remain humble and learn from those mistakes.  Get involved in activities, in community and in your faith life.  Give back and help others.  Travel and see the world, but whatever you do and wherever you go remember the importance of family.  You are a blessing and I will love you forever. [Grandma or Grandpa]</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel3a-content"
                                        id="panel2a-header"
                                    >
                                        <p>To a Spouse or Partner</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>My love, I will never be able to put into words the love I hold in my heart for you.  I am honored and proud to be your life partner.  You love others with humility and grace.  We have experienced highs and lows and you have remained steadfast, humble and calm. You introduced travel into my life and encouraged me to be more adventurous, try new things, and meet new people. Remember the time you spoke French… “Buenos dios.”  It makes me laugh out loud :)  It warms my heart watching you teach our boys about family values, a strong work ethic and respecting all people.  You put your family first and your faith at the center. I am so in love with you.</p>
                                        <p>[Insert nickname here] Honey, you are beautiful inside and out.  You are ENOUGH.  You warm my heart with your smile, creativity and empathy for others.  I remember the first day we met it was like talking to a best friend that I hadn’t seen in awhile.  You love and look after all of us.  Your patience is unmatched and we grateful for your nurturing, passionate and encouraging spirit.  I love watching you teach our children to cook, read, play sports and be a good friend.  You are amazing and I thank God everyday he brought you into my life.</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <p>To a Parent</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Thank you, Mom, for showing me how to be kind to everyone. You brought crafting, decorating and hosting into my life.  Thank you for raising me with a strong foundation of faith and showing so much forgiveness.  I hope to be as generous as you some day. I will always remember the crazy, loud, and joyful times we had near the christmas tree celebrating family time at Christmas! [Your Name]</p>
                                        <p>Thank you, Dad, for showing me how to be strong in the hard times. I appreciate all your effort and love in bringing me up to be a better individual and the sacrifices you made to provide for our family.   I hope that I can be as hardworking as you some day.  I laugh everytime I think about our roofing projects and hunting excursions.  Those are moments I will not take for granted.  Life is too short so forgive and move on.  I love you.  [Your Name]</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <p>To a Grandparent</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Dear Grandma, No words in the world can express what you mean to me Grandma.  You have always been present in my life through all the ups and downs.  You call me your little lamb and have always provided a safe place for me.  The lakehouse was a second home to me where we could have little debbies, rootbeer floats, and where you taught me how to swim.  I hope my home is filled with as much love and warmth as you have given to me. [Your Name]</p>
                                        <p>Papa, When I was a kid, you would always declare “you can go swimming, but don’t get wet.” Your humor and gentle character always put a smile on my face.  You are the strong pillar of our family, a beautiful blessing from God. You have brightened my life with your sacrifice and love. Thank you giving me such a wonderful upbringing.  [Your Name]</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <p>To a Sibling</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Hi Sis, Having a sister like you is a treasure!  We have shared countless memories together.  Staying up late talking and laughing.  Or the time we got lost, you took my hand and were so brave.  I look up to you.  You have always been there for me without judgement.  You are generous, loving, and understanding.  Even after fighting over clothes, toys, or friends we always found our way back to forgiveness and love.  Be yourself.  You have a beautiful heart.  I love you to the moon and back.  [Your Name]</p>
                                        <p>Hey Bro, I love the man you have become and how you are so strong.  Thank you for letting me dress you up and put barrettes in your hair when we were younger.  Thank you for not getting too upset when I wrapped my barbies for you as a christmas gift.  How you practiced your karate kid moves in the living room before wanting to wrestle. When I look back at our memories my heart is full of laughter.   Be silly.  Be brave.  Love God.  Never stop singing.   You mean the world to me, love you always.  [Your Name]</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}


export default InspirationMessageModal;