import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import "../../../assets/scss/playerModalStyle.scss";

class PlayerModal extends React.Component {
    render() {
        const { playerData } = this.props;

        return (
            <>
                <Dialog
                    className="sd-player-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <video controls src={playerData.media_url}>
                        </video>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default PlayerModal;