import React from "react";
import { KeyboardVoice, Movie, CloudUpload, PlayCircleFilledWhite, DeleteOutlineOutlined, PauseCircleFilled } from "@mui/icons-material"
import UploadVideoModal from "../../components/Modal/UploadVideoModal";
import AudioModal from "../../components/Modal/AudioModal";
import UploadFileModal from "../../components/Modal/UploadFileModal";
import RecordAudioModal from "../../components/Modal/RecordAudioModal";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import InspirationMessageModal from "../../components/Modal/InspirationMessageModal";
import SuccessModal from "../../components/Modal/SuccessModal";
import PlayerModal from "../../components/Modal/PlayerModal";
import LoaderComponent from '../../components/Loader';
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import moment from 'moment';
import { helper } from "../../../assets/js/utils/Element";

import "../../../assets/scss/step2Style.scss";

const Step2 = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            uploadVideoModal: false,
            audioModal: false,
            uploadAudioModal: false,
            recordAudioModal: false,
            uploads: [],
            saving: false,
            deleteModal: false,
            deleteFileId: null,
            inspirationMessageModal: false,
            fileChecked: null,
            isValidate: false,
            videoRestrictionModal: false,
            playerData: null
        };

        if(this.props.state !== null){
            this.state = this.props.state;
        }

        this.player = React.createRef();
    }
    onUploadVideoModal(status = false){
        this.setState({
            uploadVideoModal: status
        })
    }
    onAudioModal(status = false){
        this.setState({
            audioModal: status
        })
    }
    onUploadAudioModal(status = false){
        let stateObj = {
            // audioModal: false,
            uploadAudioModal: status
        };
        // if(status === false){
        //     stateObj['audioModal'] = true;
        //     stateObj['uploadAudioModal'] = status;
        // }

        this.setState(stateObj);
    }
    onRecordAudioModal(status = false){
        let stateObj = {
            // audioModal: false,
            recordAudioModal: status
        };
        // if(status === false){
        //     stateObj['audioModal'] = true;
        //     stateObj['recordAudioModal'] = status;
        // }

        this.setState(stateObj);
    }
    updateMediaUploads(blob, type, duration = null){
        const totalUploads = this.state.uploads.length;
        let name = "Audio 00"+(totalUploads + 1);
        if(type === "video"){
            name = "Video 00"+(totalUploads + 1);
        }
        const date = new Date().toISOString();
        const upload = {
            file_id: 0,
            type: type,
            hug_id: 0,
            title: name,
            updated_at: date,
            created_at: date,
            id: 0,
            media_url: window.URL.createObjectURL(blob),
            uploading: true,
            player: React.createRef(),
            playing: false,
            duration: duration,
            file: blob
        };
        this.state.uploads.push(upload);
        return {
            upload: upload,
            key: totalUploads
        };
    }
    onMediaUpload(upload, key){
        const requestData = {
            type: upload.type,
            file: upload.file,
            name: upload.title
        };
        if(upload.duration !== null){
            requestData['duration'] = upload.duration;
        }
        if(requestData.type === 'video' && requestData.duration > 40){
            let pendingUploads = this.state.uploads.slice(0,-1);

            this.setState({
                uploads: pendingUploads,
                videoRestrictionModal: true,
            });

            return false;
        }
        const source = axios.CancelToken.source();
        Api.uploadMedia(requestData, source).then(data => {
            const uploads = this.state.uploads;
            uploads[key] = {
                ...data,
                player: React.createRef(),
                playing: false
            };
            
            let fileCheckedId = this.state.fileChecked;
            if(uploads.length === 1){
                fileCheckedId = this.getFileChecked(uploads);
            }
            this.setState({
                uploads: uploads,
                fileChecked: fileCheckedId
            },() => {
                this.props.handleStateUpdate();
            });
        }).catch(err => {
            console.log(err);
        });

        return true;
    }
    onRecordingUpload(blob, duration){
        const { upload, key } = this.updateMediaUploads(blob, "audio", duration);
        this.onMediaUpload(upload, key);
        this.setState({
            uploads: this.state.uploads,
            audioModal: false,
            recordAudioModal: false,
            uploadAudioModal: false,
        });
    }
    onAudioFileModalSuccess(files){
        files.map(file => {
            this.updateMediaUploads(file, "audio");
            return null;
        });

        this.setState({
            uploads: this.state.uploads,
            audioModal: false,
            recordAudioModal: false,
            uploadAudioModal: false,
        });
    }
    onVideoFileModalSuccess(files){
        files.map(file => {
            this.updateMediaUploads(file, "video");
            return null;
        });

        this.setState({
            uploads: this.state.uploads,
            audioModal: false,
            recordAudioModal: false,
            uploadAudioModal: false,
            uploadVideoModal: false,
        });
    }
    getFileChecked(files){
        let fileChecked = null;
        files.map((file) => {
            fileChecked = file.id;

            return null;
        });

        return fileChecked;
    }
    isValid(){
        const { uploads, fileChecked } = this.state;
        let isValid = true;
        uploads.map(upload => {
            if(upload.hasOwnProperty("uploading")){
                isValid = false;
            }
            return null;
        });
        if(fileChecked === null && uploads.length !== 1){
            isValid = false;
        }

        this.setState({
            isValidate: true
        })

        return (uploads.length > 0 && isValid);
    }
    onMediaPlay(upload, key){
        if(upload.type !== 'video' && upload.player.current !== null){
            upload.player.current.play();
        }
        const uploads = this.state.uploads;
        if(upload.type !== 'video'){
            uploads[key] = {
                ...upload,
                playing: true
            };
        }
        this.setState({
            uploads: uploads,
            playerData: upload.type === 'video' ? upload : null
        });
    }
    onMediaPause(upload, key){
        if(upload.player.current !== null){
            upload.player.current.pause();
        }

        const uploads = this.state.uploads;
        uploads[key] = {
            ...upload,
            playing: false
        };
        this.setState({
            uploads: uploads,
            playerData: null
        });
    }
    onMetaDataLoaded(event, upload, key){
        const uploads = this.state.uploads;
        const duration = event.target.duration;

        let continueMediaUpload = true;
        if(upload.id <= 0 && upload.duration === null){
            upload.duration = duration;
            continueMediaUpload = this.onMediaUpload(upload, key);
        }

        if(continueMediaUpload){
            uploads[key] = {
                ...upload,
                playing: false,
            };
            if(!uploads[key].hasOwnProperty('player')){
                uploads[key]['player'] = React.createRef();
            }
            this.setState({
                uploads: uploads
            });
        }
    }
    onDeleteModal(status = false, fileId = null){
        if(status === true && fileId === null){
            return;
        }

        this.setState({
            deleteModal: status,
            deleteFileId: fileId
        })
    }
    onDeleteModalSuccess(){
        const { deleteFileId } = this.state;
        if(deleteFileId === null){
            return;
        }
        this.setState({
            saving: true
        });

        Api.deleteHugMedia(deleteFileId).then((data) => {
            this.removeMediaItem();

            this.setState({
                deleteModal: false,
                saving: false,
                deleteFileId: null
            },() => {
                this.props.handleStateUpdate();
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
                deleteFileId: null
            });
        });
    }
    removeMediaItem(){
        let pendingUploads = this.state.uploads.filter(i => i.id !== this.state.deleteFileId);
        this.setState({
            uploads: pendingUploads,
        })
    }
    handleCheckbox(e, name, value){
        this.setState({
            [name]: value
        },() => {
            this.props.handleStateUpdate();
        });
    }
    renderUpload(upload, key){
        return (
            <li key={key}>
                <div className="icon-name">
                    <div className="sd-file-checkmark">
                        <div className="sd-file-check">
                            <input 
                                className={this.state.isValidate && this.state.fileChecked === null ? "error" : "" }
                                type="checkbox" 
                                id={"fileChecked-"+key} 
                                name="fileChecked" 
                                onChange={(e) => this.handleCheckbox(e, 'fileChecked', upload.id)}
                                checked={upload.id === this.state.fileChecked}
                            />
                            <label htmlFor={"fileChecked-"+key}></label>
                        </div>
                    </div>
                    <div className="file-icon">
                        {
                            upload.type === "audio" ?
                                <KeyboardVoice />
                            :
                            <Movie />
                        }
                    </div>
                    <div className="file-name">
                        <p>{upload.title}</p>
                    </div>
                </div>
                <div className="date-actions">
                    <div className="file-date">
                        <p>{ moment(upload.created_at).format("YYYY-DD-MM") }</p>
                    </div>
                    <div className="file-duration">
                        <p>
                            {
                                upload.hasOwnProperty("duration") && upload.duration ?
                                    helper.toHHMMSS(upload.duration)
                                :
                                null
                            }
                        </p>
                    </div>
                    <div className="actions">
                        {
                            !upload.hasOwnProperty("uploading") ?
                                <>
                                    {
                                        upload.playing ?
                                            <div className="play-icon" onClick={() => this.onMediaPause(upload, key)}>
                                                <PauseCircleFilled />
                                            </div>
                                        :
                                        <div className="play-icon" onClick={() => this.onMediaPlay(upload, key)}>
                                            <PlayCircleFilledWhite />
                                        </div>
                                    }
                                    <div className="delete-icon" onClick={() => this.onDeleteModal(true, upload.id)}>
                                        <DeleteOutlineOutlined />
                                    </div>
                                </>
                            :
                                <LoaderComponent />
                        }
                        <div style={{display: "none"}}>
                            {
                                upload.type === "audio" ?
                                    <audio controls preload="metadata" ref={upload.player} src={upload.media_url} onLoadedMetadata={(event) => this.onMetaDataLoaded(event, upload, key)}>
                                    </audio>
                                :
                                    <video controls preload="metadata" ref={upload.player} src={upload.media_url} onLoadedMetadata={(event) => this.onMetaDataLoaded(event, upload, key)}>
                                    </video>
                            }
                        </div>
                    </div>
                </div>
            </li>
        )
    }
    onInspirationMessageModal(status = false){
        this.setState({
            inspirationMessageModal: status
        })
    }
    onVideoRestrictionModal(status = false){
        this.setState({
            videoRestrictionModal: status
        })
    }
    onPlayerModalClose(){
        this.setState({
            playerData: null
        })
    }
    render() {
        const { uploadVideoModal, audioModal, uploadAudioModal, recordAudioModal, uploads, saving, deleteModal,
            inspirationMessageModal, videoRestrictionModal, playerData } = this.state;
        return (
            <div className="sd-step-two-content">
                <div className="sd-step2-note">
                    <h3>Create a Heartfelt Message For Your Loved One</h3>
                    <p>Upload or record an intimate audio message for your loved one that is less than 60 seconds.  Need some inspiration?  Click <span className="audio-note" onClick={() => this.onInspirationMessageModal(true)}>Inspirational Hug Messages</span> for some ideas!</p>
                </div>
                <div className="sd-step-two-icons">
                    <div className="sd-audio-icon">
                        <div className={"sd-icon"} onClick={() => this.onRecordAudioModal(true)}>
                            <KeyboardVoice />
                        </div>
                        <p>Record Audio</p>
                    </div>
                    <p>OR</p>
                    <div className="sd-video-icon">
                        <div className={"sd-icon"} onClick={()=> this.onUploadAudioModal(true)}>
                            <CloudUpload />
                        </div>
                        <p>Upload Audio</p>
                    </div>
                </div>
                {
                    uploads.length > 0 ?
                        <div className="sd-audio-videos-list">
                            <ul>
                                {
                                    uploads.map((upload, key) => {
                                        return this.renderUpload(upload, key)
                                    })
                                }
                            </ul>
                        </div>
                 :
                    <></>
                }
                {
                    uploadVideoModal ?
                        <UploadVideoModal 
                            open={uploadVideoModal}
                            onClose={() => this.onUploadVideoModal()}
                            onSuccess={(files) => this.onVideoFileModalSuccess(files)}
                        />
                    :
                        <></>
                }
                {
                    audioModal ?
                        <AudioModal 
                            open={audioModal}
                            onClose={() => this.onAudioModal()}
                            onUpload={() => this.onUploadAudioModal(true)}
                            onRecord={() => this.onRecordAudioModal(true)}
                        />
                    :
                        <></>
                }
                {
                    uploadAudioModal ?
                        <UploadFileModal 
                            open={uploadAudioModal}
                            onClose={() => this.onUploadAudioModal()}
                            onSuccess={(files) => this.onAudioFileModalSuccess(files)}
                            accept=".mp3,.mp4a,.wav,.m4a"
                            title="Choose Audio"
                            uploadBtnText="Drag and drop to upload audio"
                        />
                    :
                        <></>
                }
                {
                    recordAudioModal ?
                        <RecordAudioModal 
                            open={recordAudioModal}
                            onClose={() => this.onRecordAudioModal()}
                            onUpload={(blob, duration) => this.onRecordingUpload(blob, duration)}
                        />
                    :
                        <></>
                }
                {
                    deleteModal ?
                        <ConfirmationModal
                            open={deleteModal}
                            saving={saving} 
                            onClose={() => this.onDeleteModal(false)} 
                            onSuccess={() => this.onDeleteModalSuccess()}
                        />
                    :
                        <></>
                }
                {
                    inspirationMessageModal ?
                        <InspirationMessageModal
                            open={inspirationMessageModal}
                            onClose={() => this.onInspirationMessageModal(false)} 
                        />
                    :
                        <></>                        
                }
                {
                    videoRestrictionModal ?
                        <SuccessModal
                            open={videoRestrictionModal}
                            title="Please upload a video with a duration of less than 40 seconds."
                            buttonText="Close"
                            onClose={() => this.onVideoRestrictionModal(false)}
                            onButtonAction={() => this.onVideoRestrictionModal(false)}
                        />
                    :
                        <></>
                }{
                    playerData !== null ?
                        <PlayerModal
                            open={true}
                            playerData={playerData}
                            onClose={() => this.onPlayerModalClose()}
                        />
                    :
                        <></>
                }
            </div>
        );
    }
}

export default Step2;