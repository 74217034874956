import React from "react";
import { PhotoSizeSelectActual, OpenWith, DeleteOutlineOutlined } from "@mui/icons-material"
import UploadFileModal from "../../components/Modal/UploadFileModal";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
// import { sortable } from "react-sortable";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import LoaderComponent from '../../components/Loader';
import { sortable } from "../../../assets/js/sort/Sortable";

import "../../../assets/scss/step3Style.scss";

class PhotoItem extends React.Component {
    render() {
        const { items, ...rest } = this.props;
        return (
            <li {...rest}>
                {this.props.children}
            </li>
        )
    }
}
const SortableItem = sortable(PhotoItem);

const Step3 = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            uploadVideoModal: false,
            photos: [],
            limit: 5,
            saving: false,
            deleteModal: false,
            deleteFileId: null
        };

        if(this.props.state !== null){
            this.state = this.props.state;
        }
    }
    onUploadVideoModal(status = false){
        this.setState({
            uploadVideoModal: status
        });
    }
    onMediaUpload(blob, stateUpdate = true){
        const totalPhotos = this.state.photos.length;
        let name = "Photo 00"+(totalPhotos + 1);
        const requestData = {
            type: "photo",
            file: blob,
        };
        const source = axios.CancelToken.source();
        const date = new Date().toISOString();
        this.state.photos.push({
            file_id: 0,
            type: "photo",
            hug_id: 0,
            title: name,
            updated_at: date,
            created_at: date,
            id: 0,
            media_url: window.URL.createObjectURL(blob),
            uploading: true
        });
        if(stateUpdate){
            this.setState({
                photos: this.state.photos,
                uploadVideoModal: false
            });
        }

        Api.uploadMedia(requestData, source).then(data => {
            const photos = this.state.photos;
            photos[totalPhotos] = data;
            this.setState({
                photos: photos
            },() => {
                this.props.handleStateUpdate();
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onFileModalSuccess(files){
        let availableLimit = this.state.limit - this.state.photos.length;
        if(availableLimit > 0){
            files.splice(availableLimit);
            files.map(file => {
                this.onMediaUpload(file, false);
                return null;
            });
        }

        this.setState({
            photos: this.state.photos,
            uploadVideoModal: false,
        });
    }
    onSortItems(items){
        this.setState({
            photos: items
        });
    }
    isValid(){
        return true;
        // const { photos } = this.state;
        // let isValid = true;
        // photos.map(photo => {
        //     if(photo.hasOwnProperty("uploading")){
        //         isValid = false;
        //     }
        //     return null;
        // });
        // return (photos.length > 0 && isValid);
    }
    onDeleteModal(status = false, fileId = null){
        if(status === true && fileId === null){
            return;
        }

        this.setState({
            deleteModal: status,
            deleteFileId: fileId
        })
    }
    onDeleteModalSuccess(){
        const { deleteFileId } = this.state;
        if(deleteFileId === null){
            return;
        }
        this.setState({
            saving: true
        });

        Api.deleteHugMedia(deleteFileId).then((data) => {
            this.removeMediaItem();

            this.setState({
                deleteModal: false,
                saving: false,
                deleteFileId: null
            },() => {
                this.props.handleStateUpdate();
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
                deleteFileId: null
            });
        });
    }
    removeMediaItem(){
        let pendingPhotos = this.state.photos.filter(i => i.id !== this.state.deleteFileId);
        
        this.setState({
            photos: pendingPhotos
        })
    }
    render() {
        const { uploadVideoModal, limit, photos, saving, deleteModal } = this.state;
        
        return (
            <div className="sd-step-three-content">
                <div className="sd-step3-note">
                    <h3>Choose Special Photos Of Your Best Memories</h3>
                    <p>Choose up to 5 photos and order them how you want them to appear in your forever hug by clicking on the “arrows” icon on the top left of each image.</p>
                </div>
                <div className="sd-step-three-icon">
                    <div className="sd-icon" onClick={() => this.onUploadVideoModal(true)}>
                        <PhotoSizeSelectActual />
                    </div>
                    <p>Choose Photos</p>
                </div>
                {
                    photos.length > 0 ?
                        <div className="sd-step-three-photos-list">
                            {
                                photos.map((photo, key) => (
                                    <SortableItem
                                        key={key}
                                        onSortItems={(items) => this.onSortItems(items)}
                                        items={photos}
                                        sortId={key}
                                    >
                                        <div className="sd-step-three-photo">
                                            <img src={photo.media_url} alt="photo_one" />
                                            <div className="move-icon">
                                                <OpenWith />
                                            </div>
                                            <div className="delete-icon" onClick={() => this.onDeleteModal(true, photo.id)}>
                                                <DeleteOutlineOutlined />
                                            </div>
                                            {
                                                photo.hasOwnProperty("uploading") && photo.uploading ?
                                                    <LoaderComponent />
                                                :
                                                null
                                            }
                                        </div>
                                    </SortableItem>
                                ))
                            }
                        </div>
                    :
                        <></>
                }
                {
                    uploadVideoModal ?
                        <UploadFileModal 
                            open={uploadVideoModal}
                            onClose={() => this.onUploadVideoModal()}
                            onSuccess={(files) => this.onFileModalSuccess(files)}
                            accept="image/*"
                            fileType="image"
                            limit={limit}
                            title="Choose up to 5 photos."
                            uploadBtnText="Drag and drop to upload photos"
                        />
                    :
                        <></>
                }
                {
                    deleteModal ?
                        <ConfirmationModal
                            open={deleteModal}
                            saving={saving} 
                            onClose={() => this.onDeleteModal(false)} 
                            onSuccess={() => this.onDeleteModalSuccess()}
                        />
                    :
                        <></>
                }
            </div>
        );
    }
}

export default Step3;