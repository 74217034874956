import React from "react";
import { Dialog, DialogContent, DialogTitle, Button, IconButton, Autocomplete, createFilterOptions,
    TextField, FormControl, InputLabel, Select } from "@mui/material";
import { Close, Error, Done } from "@mui/icons-material";
import LoaderComponent from '../Loader';
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import { helper } from '../../../assets/js/utils/Element';
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import moment from 'moment';
import ConfirmationModal from "./ConfirmationModal";

import "../../../assets/scss/addRecipientModalStyle.scss";

const filter = createFilterOptions();

class AddRecipientModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hug: props.hug,
            saving: false,
            recipients: this.getRecipients(props.hug),
            relationshipOptions: helper.relationshipOptions(),
            relationshipAdd: "",
            isValidate: false,
            recipientChecked: [],
            removeRecipientModal: false,
            removing: false,
            displayCheckedError: false,
        }
    }
    componentWillReceiveProps(props){
        console.log(props.hug);
        this.setState({
            hug: props.hug,
            recipients: this.getRecipients(props.hug),
        })
    }
    getRecipients(hug){
        let hugRecipients = hug.recipients;
        let recipients = [];
        hugRecipients.map(recipient => {
            recipients.push({
                id: recipient.id,
                first_name: recipient.first_name,
                email: recipient.email,
                relationship: recipient.relationship,
                hug_email: recipient.hug_email,
                hug_email_date: recipient.hug_email_date,
                hug_view: recipient.hug_view,
                hug_creator_email: recipient.hug_creator_email
            });

            return null;
        });

        return recipients;
    }
    onRemoveRecipientModal(status = false){
        this.setState({
            removeRecipientModal: status
        })
    }
    removeRecipient(){
        let { recipients }  = this.state;
        let removedRecipient = recipients[recipients.length-1];
        recipients.splice(recipients.length-1, 1);  

        if(!removedRecipient.hasOwnProperty("id")){
            this.setState({
                recipients: recipients,
                removeRecipientModal: false
            });

            return;
        }

        
        let requestData = {
            id: removedRecipient.id,
        };
        this.setState({
            removing: true
        });
        const source = axios.CancelToken.source();
        Api.removeRecipient(requestData, source).then(data => {
            this.setState({
                recipients: recipients,
                removeRecipientModal: false,
                removing: false,
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                removing: false,
            });
        });
    }
    cloneInputs = () => {
        this.setState({
            isValidate: false,
            recipients: [
                ...this.state.recipients,
                {
                    first_name: '',
                    email: '',
                    relationship: '',
                    hug_email: 0,
                    hug_email_date: null,
                    hug_view: 0,
                    hug_creator_email: 0
                }
            ]
        })
    }
    handleRecipientChange(e, key, name){
        let recipients = this.state.recipients;
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        recipients[key][name] = value;

        this.setState({
            recipients: recipients
        });
    }
    handleRelationshipChange(e, newValue, key, name){
        let recipients = this.state.recipients;
        
        let value = '';
        let newValueAdded = false;

        if (typeof newValue === 'string') {
            value = newValue;
        } else if (newValue && newValue.inputValue) {
            value = newValue.inputValue;
            newValueAdded = true;
        } else if(newValue && newValue.value) {
            value = newValue.key;
        }

        recipients[key][name] = value;

        this.setState({
            recipients: recipients
        },() => {
            if(newValueAdded){
                this.setState({
                    relationshipAdd: "",
                    recipients: recipients,
                    relationshipOptions: [
                        ...this.state.relationshipOptions,
                        {
                            key: value,
                            value: value
                        }
                    ],
                });
            }
        });
    }
    handleRelationshipType(e){
        this.setState({
            relationshipAdd: e.target.value
        })
    }
    handleRelationshipAdd(e, key, name){
        e.preventDefault();

        let recipients = this.state.recipients;
        let value = this.state.relationshipAdd;

        recipients[key][name] = value;

        this.setState({
            relationshipAdd: "",
            recipients: recipients,
            relationshipOptions: [
                ...this.state.relationshipOptions,
                {
                    key: value,
                    value: value
                }
            ],
        });
    }
    isValid(){
        const { recipients } = this.state;

        let isValid = true;
        recipients.map(recipient => {
            if(recipient.first_name.length <= 0){
                isValid = false;
            }
            var emailRegex = /\S+@\S+\.\S+/;
            if(recipient.email.length <= 0 || !emailRegex.test(recipient.email)){
                isValid = false;
            }
            if(recipient.relationship.length <= 0){
                isValid = false;
            }

            return null;
        });

        if(this.getCheckedEmails().length <= 0){
            isValid = false;
        }

        this.setState({
            isValidate: true
        })

        return isValid;
    }
    isEmailValid(email){
        let isValid = true;

        var emailRegex = /\S+@\S+\.\S+/;
        if(email.length <= 0 || !emailRegex.test(email)){
            isValid = false;
        }

        return isValid;
    }
    saveRecipient(e){
        const isValid = this.isValid();
        if(!isValid){
            return;
        }
        const { recipients, hug } = this.state;
        
        let requestData = {
            id: hug.id,
            recipients: []
        };
        recipients.map((recipient) => {
            requestData.recipients.push({
                first_name: recipient.first_name,
                email: recipient.email,
                relationship: recipient.relationship,
                hug_email: recipient.hug_email,
                hug_email_date: recipient.hug_email_date,
                hug_view: recipient.hug_view,
                hug_creator_email: recipient.hug_creator_email
            });
            return null;
        });
        this.setState({
            saving: true,
        })
        const source = axios.CancelToken.source();
        Api.addRecipients(requestData, source).then(data => {
            this.setState({
                response: data,
                saving: false,
            });

            this.props.onAddRecipients(data.recipients);
            // this.props.onClose();
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
            });
        });
    }
    getCheckedEmails(){
        const { recipients, recipientChecked } = this.state;

        let emails = [];
        recipients.map((recipient, key) => {
            if(recipientChecked.includes(key)){
                emails.push(recipient.email)
            }

            return null;
        });

        return emails;
    }
    onSendEmail(e){
        const isValid = this.isValid();
        if(!isValid){
            return;
        }
        const { recipients, hug } = this.state;

        let requestData = {
            id: hug.id,
            recipients: [],
            recipientsEmail: this.getCheckedEmails()
        };

        recipients.map((recipient) => {
            requestData.recipients.push({
                first_name: recipient.first_name,
                email: recipient.email,
                relationship: recipient.relationship,
                hug_email: recipient.hug_email,
                hug_email_date: recipient.hug_email_date,
                hug_view: recipient.hug_view,
                hug_creator_email: recipient.hug_creator_email
            });
            return null;
        });

        this.setState({
            sending: true
        })

        const source = axios.CancelToken.source();
        Api.sendRecipientsEmail(requestData, source).then(data => {
            this.setState({
                response: data,
                sending: false,
            });

            this.props.onSendEmail(data.hug);
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                sending: false,
            });
        });
    }
    handleCheckbox(e, key, recipient){
        const { recipientChecked } = this.state;
        const currentIndex = recipientChecked.indexOf(key);
        const newChecked = [...recipientChecked];
    
        if (currentIndex === -1) {
            newChecked.push(key);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        
        this.setState({
            recipientChecked: newChecked
        });
    }
    renderErrorMessages(){
        return <div className={"sd-recipient-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">Please select a recipient to send your hug to.</span>
        </div>;
    }
    render() {
        const { saving, sending, recipients, isValidate, relationshipOptions, relationshipAdd, recipientChecked, removeRecipientModal, removing } = this.state;
        
        return (
            <>
                <Dialog
                    maxWidth="lg"
                    fullWidth={true}
                    open={this.props.open}
                    className="sd-recipient-modal"
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        <h5 className={"modal-title"}>Hug Recipients</h5>
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-recipient-modal-content">
                            <div className="sd-recipient-sets-container">
                                <div className="sd-step-one-form-content">
                                    {
                                        isValidate && this.getCheckedEmails().length <= 0 ?
                                            this.renderErrorMessages()
                                        :
                                            <></>
                                    }
                                    {
                                        recipients.map((recipient, key) => (
                                            <div className="sd-step-one-inputs" key={key}>
                                                <div className="element-container sd-file-checkmark">
                                                    <div className="sd-file-check">
                                                        <input 
                                                            className={""}
                                                            type="checkbox" 
                                                            id={"recipientChecked-"+key} 
                                                            name="recipientChecked" 
                                                            onChange={(e) => this.handleCheckbox(e, key, recipient)}
                                                            checked={recipientChecked.includes(key)}
                                                        />
                                                        <label htmlFor={"recipientChecked-"+key}></label>
                                                    </div>
                                                </div>
                                                <div className="element-container">
                                                    <input 
                                                        className={isValidate && recipient.first_name.length <= 0 ? 'error' : ''}
                                                        type={"text"} 
                                                        placeholder="First Name*" 
                                                        onChange={(e) => this.handleRecipientChange(e,key, 'first_name')}    
                                                        value={recipient.first_name}
                                                    />
                                                    {
                                                        isValidate && recipient.first_name.length <= 0 ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="element-container">
                                                    <input 
                                                        className={isValidate && !this.isEmailValid(recipient.email) ? 'error' : ''}
                                                        type={"email"} 
                                                        placeholder="Email*" 
                                                        onChange={(e) => this.handleRecipientChange(e,key, 'email')}
                                                        value={recipient.email}
                                                    />
                                                    {
                                                        isValidate && !this.isEmailValid(recipient.email) ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="element-container relationship-element">
                                                    <Autocomplete
                                                        value={recipient.relationship}
                                                        onChange={(e, newValue) => this.handleRelationshipChange(e, newValue, key, 'relationship')}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            const { inputValue } = params;
                                                            const isExisting = options.some((option) => inputValue === option.value);
                                                            if (inputValue !== '' && !isExisting) {
                                                                filtered.push({
                                                                    inputValue,
                                                                    value: `Add "${inputValue}"`,
                                                                });
                                                            }
                                                    
                                                            return filtered;
                                                        }}
                                                        openOnFocus
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        freeSolo
                                                        options={relationshipOptions}
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === 'string') {
                                                            return option;
                                                            }
                                                            if (option.inputValue) {
                                                            return option.inputValue;
                                                            }
                                                            return option.value;
                                                        }}
                                                        renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Relationship *" />
                                                        )}
                                                    />
                                                </div>
                                                <div className="element-container">
                                                    <FormControl>
                                                        <InputLabel>Relationship *</InputLabel>
                                                        <Select
                                                            onChange={(e) => this.handleRecipientChange(e, key, 'relationship')}
                                                            className={"sd-relationship-select "+(isValidate && recipient.relationship.length <= 0 ? 'error' : '')}
                                                            MenuProps={{
                                                                className: 'sd-relationship-select-menu'
                                                            }}
                                                            value={recipient.relationship}
                                                        >
                                                            { helper.renderOptions(relationshipOptions) }
                                                            <div className="sd-custom-relationship-value">
                                                                <form onSubmit={(e) => this.handleRelationshipAdd(e, key, 'relationship')}>
                                                                    <label htmlFor="other">Other</label>
                                                                    <input 
                                                                        type="text" 
                                                                        id="other" 
                                                                        name="other" 
                                                                        value={relationshipAdd}
                                                                        onChange={(e) => this.handleRelationshipType(e)}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key !== "Escape") {
                                                                                e.stopPropagation();
                                                                            }
                                                                        }}
                                                                    />
                                                                </form>
                                                            </div>
                                                        </Select>
                                                    </FormControl>
                                                    {
                                                        isValidate && recipient.relationship.length <= 0 ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                {
                                                    recipient.hug_email ?
                                                        <div className="element-container sd-email-sent-detail">
                                                            <Done className={""} />
                                                            <p>{moment(recipient.hug_email_date).format('ll')}</p>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        ))
                                    }
                                    <div className="sd-step-one-form-btns">
                                        <Button className="button radius-8" onClick={this.cloneInputs}>
                                            Add Another
                                        </Button>
                                        {
                                            recipients.length > 1 ?
                                                <Button className="button remove radius-8" onClick={() => this.onRemoveRecipientModal(true)}>
                                                    Remove Recipient
                                                </Button>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="sd-recipient-save">
                                <Button className={"button radius-8"} onClick={(e) => this.saveRecipient(e)}>
                                    {
                                        saving ?
                                            <LoaderComponent />
                                        :
                                        "Save"
                                    }
                                </Button>
                                <Button className={"button radius-8"} onClick={(e) => this.onSendEmail(e)}>
                                    {
                                        sending ?
                                            <LoaderComponent />
                                        : recipientChecked.length > 0 ?
                                            "Send"
                                        :
                                            "Send"
                                    }
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                    {
                        removeRecipientModal ? 
                            <ConfirmationModal
                                open={removeRecipientModal}
                                onClose={() => this.onRemoveRecipientModal(false)} 
                                onSuccess={() => this.removeRecipient()}
                                title="Are you sure you want to remove recipient?"
                                saving= {removing}
                            />
                        :
                            <></>
                    }
                </Dialog>
            </>
        )
    }
}
export default connect(mapStateToProps)(AddRecipientModal);