import React, { Component } from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { Button } from "@mui/material";
import logo from "../../../assets/img/forember_logo.png";
import signUpImg from "../../../assets/img/signup_img.png";
import { Link as RouterLink } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Facebook, Google, Error } from "@mui/icons-material";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import Cookie from '../../../assets/js/utils/Cookie';
import { helper } from "../../../assets/js/utils/Element";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import LoaderComponent from '../../components/Loader'
import { LoginSocialFacebook, IResolveParams, LoginSocialGoogle } from 'reactjs-social-login';
import Config from "../../../../Config";

import "../../../assets/scss/loginStyle.scss";

class Signup extends Component {
    constructor(props){
        super(props);

        let email = '';
        let emailParam = decodeURIComponent(helper.getParam("email"));
        if(emailParam && emailParam !== "null" && emailParam.length > 0){
            email = emailParam;
        }

        this.state = {
            first_name: '',
            last_name: '',
            email: email,
            phone: '',
            password: "",
            phoneCheckmark: false,
            terms: true,
            loading: false,
            validation: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                terms: '',
                code: '',
                phoneCheckmark: '',
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            showError: false,
            errorMessage: "",
            user: null,
            message: "",
            code: ""
        };

        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmSignUp = this.confirmSignUp.bind(this);
    }
    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handlePhoneChange(e, name) {
        const { phone } = this.state;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^[+\d]+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = phone;
        }
        this.setState({
            [name]: state[name]
        });
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        let values = this.state;

        const requestData = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            password: values.password,
            terms: values.terms,
            
            grant_type: "password",
            client_id: Config.getApiClientId(),
            client_secret: Config.getApiClientSecret(),
            username: values.email,
            scope: ""
        };
        const source = axios.CancelToken.source();

        this.setState({loading: true, showError: false, cancelToken: source});
        Api.signUp(requestData, source).then(data => {
            this.setState({
                loading: false, 
                requestCode: true,
                user: data.user,
                message: data.message
            });

            //this.handleLoginSuccess(data);
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    loading: false, 
                    cancelToken: null,
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    validateForm(){
        let validation = {
            first_name: "success",
            last_name: "success",
            email: "success",
            password: "success",
            terms: "success",
            phoneCheckmark: "success",
            isValid: true
        };

        if(this.state.first_name.length <= 2){
            validation.first_name = "error";
            validation.isValid = false;
        }
        if(this.state.last_name.length <= 2){
            validation.last_name = "error";
            validation.isValid = false;
        }

        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.terms === false){
            validation.terms = "error";
            validation.isValid = false;
        }

        if(this.state.phone.length > 0 && !this.state.phoneCheckmark){
            validation.phoneCheckmark = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    resendCode(event){
        event.preventDefault();
        event.stopPropagation();
        const requestData = {
            email: this.state.email,
        };
        const source = axios.CancelToken.source();
        Api.resendCode(requestData, source).then(data => {
            this.setState({
                loading: false, 
            });
        }).catch(err => {
            
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    confirmSignUp(event){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.code.length <= 0) {
            this.setState({
                ...this.state,
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const source = axios.CancelToken.source();
        const code = this.state.code;
        this.setState({loading: true, showError: false, cancelToken: source});

        let values = this.state;
        const requestData = {
            code: code,
            email: values.email,
            phone: values.phone,
            password: values.password,
            grant_type: "password",
            client_id: Config.getApiClientId(),
            client_secret: Config.getApiClientSecret(),
            username: values.email,
            scope: ""
        };
        Api.confirmSignUp(requestData, source).then(data => {
            this.handleLoginSuccess(data);
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    loading: false, 
                    cancelToken: null,
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    handleLoginSuccess(data){        
        Cookie.write("access_token", data.access_token);
        Cookie.write("refresh_token", data.refresh_token);
        Cookie.write("expires_in", data.expires_in);
        
        localStorage.setItem("forember_userinfo", JSON.stringify(data.user));
        let redirectUrl = "/home";
        
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            redirectUrl = returnUrl;
            const reminderParam = decodeURIComponent(helper.getParam("reminder"));
            if(reminderParam && reminderParam === '1'){
                redirectUrl += "?reminder=1";
            }
        }

        if(localStorage.getItem("forember_hug") !== null){
            redirectUrl = "/create-hug";
        }
        
        this.props.dispatch({
            type: "LOGIN",
            state: {
                authorized: true,
                user: data.user
            }
        });

        this.props.history.push(redirectUrl);
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    onLinkClick(href){
        let redirectUrl = this.getUrl(href);
        
        this.props.history.push(redirectUrl);
    }
    getUrl(href){
        let redirectUrl = href;

        if(href === '/login'){
            const returnUrl = decodeURIComponent(helper.getParam("return"));
            if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
                redirectUrl += "?return="+returnUrl;
                const reminderParam = decodeURIComponent(helper.getParam("reminder"));
                if(reminderParam && reminderParam === '1'){
                    redirectUrl += "&reminder=1";
                }
            }
        }

        return redirectUrl;
    }
    handleLoginSubmit(provider, profile){
        console.log(provider);
        console.log(profile);
        let accessToken = "";
        if(profile.hasOwnProperty("accessToken")){
            accessToken = profile.accessToken;
        }else{
            accessToken = profile.access_token;
        }

        const source = axios.CancelToken.source();

        this.setState({loading: true, showError: false, cancelToken: source});

        const requestData = {
            grant_type: "password",
            client_id: Config.getApiClientId(),
            client_secret: Config.getApiClientSecret(),
            scope: "",
            provider: provider,
            email: profile.email,
            access_token: accessToken
        }
        Api.socialLogin(requestData, source).then(data => {
            this.handleLoginSuccess(data, true);
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    loading: false, 
                    cancelToken: null,
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    render() {
        const { loading, validation, showError, requireLowerletter, requireUpperletter, requireNumber, requireSymbol, 
            requireLength, first_name, last_name, email, phone, phoneCheckmark, password, requestCode, code } = this.state;
        
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const { authorized } = this.props.state;

        if(authorized){
            this.props.history.push("/home");
        }
        const REDIRECT_URI = window.location.href;
        
        return (
            <>
                <div className="">
                    <div className='sd-login-page-content'>
                        <div className="sd-form-section">
                            <div className='sd-login-form-section'>
                                <RouterLink to="/" className={"brand"} onClick={() => this.onLinkClick("/")} >
                                    <img src={logo} alt={process.env.REACT_APP_MENU_TITLE} />
                                </RouterLink>
                                {
                                    requestCode ?
                                        <div className="sd-verify-account">
                                            <h1>Verify Your Account</h1>
                                            <p>We have sent a code by email. Please check your spam or junk folder if you could not receive email in Inbox. Enter it below to confirm your account.</p>
                                            <div>
                                                {
                                                    showError ?
                                                        this.renderErrorMessages()
                                                    :
                                                        <></>
                                                }
                                                <form onSubmit={this.confirmSignUp} noValidate>
                                                    <div className="form-inputs">
                                                        <div className="element-container">
                                                            <input
                                                                className={validation.code === "error" ? 'error' : ''}
                                                                type="text" 
                                                                placeholder="Verification Code"
                                                                onChange={(e) => this.handleChange(e,'code')}
                                                                value={code}
                                                            />
                                                        </div>
                                                    </div>                              
                                                    <div>
                                                        {
                                                            loading ?
                                                                <LoaderComponent />
                                                            :
                                                                <>
                                                                    <Button type="submit" className="button">
                                                                        Verify Account
                                                                    </Button>
                                                                    <p className="resend-code"><span>Didn't receive a code?</span>&nbsp;<span className={"anchor"} onClick={(e) => this.resendCode(e)}>Resend it</span></p>
                                                                </>
                                                        }
                                                    </div>
                                                </form>
                                            </div>                        
                                        </div>
                                    :
                                        <form className={"form"} onSubmit={this.handleSubmit} noValidate>
                                            <h1>Create Your Account</h1>
                                            <div className="buttons-group social-buttons">
                                                <LoginSocialFacebook
                                                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                                                    fieldsProfile={
                                                        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                    }
                                                    redirect_uri={REDIRECT_URI}
                                                    onResolve={({ provider, data }: IResolveParams) => {
                                                        this.handleLoginSubmit(provider, data)
                                                    }}
                                                    onReject={err => {
                                                        console.log(err);
                                                    }}
                                                >
                                                    <Button size={"lg"} className={"button facebook radius-8"}>
                                                        <Facebook />Sign-in with Facebook
                                                    </Button>
                                                </LoginSocialFacebook>
                                                
                                                <LoginSocialGoogle
                                                    client_id={Config.getGoogleClientId()}
                                                    redirect_uri={REDIRECT_URI}
                                                    scope="openid profile email"
                                                    access_type="offline"
                                                    onResolve={({ provider, data }: IResolveParams) => {
                                                        this.handleLoginSubmit(provider, data)
                                                    }}
                                                    onReject={err => {
                                                        console.log(err);
                                                    }}
                                                >
                                                    <Button size={"lg"} className={"button google radius-8"}>
                                                        <Google />Sign-in with Google
                                                    </Button>
                                                </LoginSocialGoogle>
                                            </div>
                                            <div className="or-divider">
                                                <p>OR</p>
                                            </div>
                                            {
                                                showError ?
                                                    this.renderErrorMessages()
                                                :
                                                <></>
                                            }
                                            <div className="form-inputs">
                                                <div className="two-inputs">
                                                    <div className="element-container">
                                                        <input 
                                                            className={validation.first_name === "error" ? 'error' : ''}
                                                            type="text" 
                                                            placeholder="First Name"
                                                            onChange={(e) => this.handleChange(e,'first_name')}
                                                            value={first_name}
                                                        />
                                                        {
                                                            validation.first_name === "error" ?
                                                                <Error />
                                                            :
                                                                <></>
                                                        }
                                                    </div>
                                                    <div className="element-container">
                                                        <input 
                                                            className={validation.last_name === "error" ? 'error' : ''}
                                                            type="text" 
                                                            placeholder="Last Name"
                                                            onChange={(e) => this.handleChange(e,'last_name')}
                                                            value={last_name}
                                                        />
                                                        {
                                                            validation.last_name === "error" ?
                                                                <Error />
                                                            :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="element-container">
                                                    <input 
                                                        className={validation.email === "error" ? 'error' : ''}
                                                        type="email" 
                                                        placeholder="Email Address"
                                                        onChange={(e) => this.handleChange(e,'email')}
                                                        value={email}
                                                    />
                                                    {
                                                        validation.email === "error" ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                {
                                                    false ?
                                                        <>
                                                            <div className="element-container">
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Phone (+1XXXXXXXXXX)"
                                                                    onChange={(e) => this.handlePhoneChange(e,'phone')}
                                                                    value={phone}
                                                                />
                                                            </div>
                                                            <div className={"sd-phone-checkmark"}>
                                                                <div className="sd-remind-fields-check">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id="phoneCheckmark" 
                                                                        name="phoneCheckmark" 
                                                                        onChange={(e) => this.handleCheckbox(e, 'phoneCheckmark')}
                                                                        checked={phoneCheckmark}
                                                                    />
                                                                    <label htmlFor="phoneCheckmark"></label>
                                                                </div>
                                                                <div className="sd-remind-value">
                                                                    <label className={validation.phoneCheckmark === 'error' ? 'error' : ''} htmlFor="phoneCheckmark">I agree to receive text messages from Forember.</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                                <div className="element-container">
                                                    <input 
                                                        className={validation.password === "error" ? 'error' : ''}
                                                        type="password" 
                                                        placeholder="Password"
                                                        onChange={(e) => this.handleChange(e,'password')}
                                                        value={password}
                                                    />
                                                    {
                                                        validation.password === "error" ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                                {
                                                    showPasswordErrors ?
                                                        <div className="password-form-error">
                                                            <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                            </div>
                                                            <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                            </div>
                                                            <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                            </div>
                                                            <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                            </div>
                                                            <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                            </div>
                                                        </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            <div className="switch-terms switch-forgot switch-button">
                                                <FormControlLabel 
                                                    className={validation.terms === "error" ? 'error' : ''}
                                                    control={
                                                        <Switch 
                                                            checked={this.state.terms}
                                                            onChange={(e) => this.handleCheckbox(e, 'terms')}
                                                        />
                                                    } 
                                                    label={(
                                                        <div>By signing up you confirm that you're 13 years or older and agree to the Forember <RouterLink onClick={() => this.onLinkClick("/terms")} to="/terms">Terms</RouterLink> and <RouterLink onClick={() => this.onLinkClick("/privacy-policy")} to="/privacy-policy">Privacy Policy</RouterLink>.</div>
                                                    )}
                                                />
                                            </div>
                                            <div className="buttons-group">
                                                {
                                                    loading ?
                                                        <LoaderComponent />
                                                    :
                                                    <>
                                                        <RouterLink to={this.getUrl("/login")}  onClick={() => this.onLinkClick("/login")} className={""}>
                                                            <Button size={"lg"} className={"button transparent radius-8"}>        
                                                                Sign in
                                                            </Button>
                                                        </RouterLink>
                                                        <Button size={"lg"} type="submit" className={"button radius-8"}>
                                                            Create Account
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                        <div className="sd-img-section sd-signup-img">
                            <img src={signUpImg} alt="signup-img" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default connect(mapStateToProps)(Signup);
