import Config from '../../../../Config';
import axios from 'axios';
import querystring from "querystring";

const ApiHelper = {
    countries: [],
    countryStates: [],
    getDefaultMemberObject(){
        return  {
            [Config.getSfdcNamespace()+'firstName']: "",
            isSoftDeleted: false,
            lastName: "",
            userEmail: "",
            userId: "",
            BusinessAdmin__c: false,
            SuperAdmin__c: false,
            Beta__c: false,
        };
    },
    prepareMemberObject(member){
        const sfdcNamespace = Config.getSfdcNamespace();
        const defaultObject = this.getDefaultMemberObject();
        let memberObject = Object.assign({}, defaultObject); //Create copy to avoid reference copy
        memberObject = Object.assign(memberObject,member);
        if(member.hasOwnProperty("Id")){
            memberObject['userId'] = member.Id;
        }
        if(member.hasOwnProperty(sfdcNamespace+"AuthorizationEmail__c")){
            memberObject['userEmail'] = member[sfdcNamespace+"AuthorizationEmail__c"];
        }
        for(var attrib in memberObject){
            try{
                let newAttrib = attrib.replace(sfdcNamespace, "");
                if(typeof(memberObject[attrib]) === "string"){
                    memberObject[newAttrib] = memberObject[attrib].trim();
                }else{
                    memberObject[newAttrib] = memberObject[attrib];
                }
                if(newAttrib !== attrib){
                    delete memberObject[attrib];
                }
            }catch(e){ }
        }
        return memberObject;
    },
    login(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/login";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    signUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/signup";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    confirmSignUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/verify";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    resendCode(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/resend";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    revokeAccessToken(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/token/revoke";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    socialLogin(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/login/social";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    updateUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"/user/profile";         
            axios.put(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    uploadUserPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            headers['Content-Type'] = 'multipart/form-data';
            const ApiUrl = Config.getApiUrl()+"/user/photo";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(function (err) {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
            
        });
    },
    uploadMedia(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            headers['Content-Type'] = 'multipart/form-data';
            const formData = new FormData();
            for(var key in requestData){
                formData.append(key, requestData[key]);
            }

            const ApiUrl = Config.getApiUrl()+"/user/hugs/media";
            axios.post(ApiUrl, formData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    createHug(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hugs";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    generateHugs(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hugs/generate?"+querystring.stringify(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteHug(hugId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"/user/hug/"+hugId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    cancelDeleteHug(hugId, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/cancel-delete/"+hugId;
            axios.post(ApiUrl, JSON.stringify(), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteHugMedia(mediaId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"/user/hugs/media/"+mediaId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    sendContactRequest(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/help/contact";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    sendInvitation(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/invite";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    getUserHugs(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hugs?"+querystring.stringify(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getUserHug(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/"+requestData.id+"?"+querystring.stringify(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getHug(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/hug/view?"+querystring.stringify(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getNotifications(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/notifications?"+querystring.stringify(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    createHugReminders(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/reminder/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    unsubscribeEmails(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"/user/unsubscribe-emails";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    getHugReminders(id, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/reminders/"+id;
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    sendRecipientsEmail(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/email/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    sendCreatorEmail(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/creator/email/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    addRecipients(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/add-recipients/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    removeRecipient(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"/user/hug/remove-recipient/"+requestData.id;
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data.body);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
}

export default ApiHelper;
export { ApiHelper };