import React from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Toolbar, IconButton, Hidden, Drawer, Menu, MenuItem, Divider, Button, Popover } from "@mui/material";
import { Menu as MenuIcon, Close, Search, Notifications, Logout } from "@mui/icons-material";
import logo from "../../../assets/img/forember_logo.png";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import AuthApi from '../../../assets/js/utils/Auth';
import LoaderComponent from '../../components/Loader'
import { helper } from "../../../assets/js/utils/Element";
import { Link as RouterLink } from "react-router-dom";
import { List, ListItem } from "@mui/material";

import "../../../assets/scss/headerStyle.scss";

class Header extends React.Component {
    constructor(props) {
        super(props);
        
        let searchParam = decodeURIComponent(helper.getParam("search"));

        this.state = {
            mobileOpen: this.props.open,
            color: this.props.color,
            popupOpen: false,
            notificationsOpen: false,
            anchorEl: null,
            logout: false,
            mobileSearh: false,
            notificationsResponse: null,
            notificationsLoading: false,
            searchValue: searchParam && searchParam !== "null" ? searchParam : ''
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.headerColorChange = this.headerColorChange.bind(this);
    }
    handleMobileSearch(){
        if (!this.state.mobileSearh) {
            document.body.classList.add('sd-active-search');
        } else {
            document.body.classList.remove('sd-active-search');
        }
        this.setState({ mobileSearh: !this.state.mobileSearh });
    }
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    componentWillReceiveProps(props){
        this.setState({
            color: props.color,
            mobileOpen: props.open
        });
        if (this.props.changeColorOnScroll) {
            const that = this;
            setTimeout(function(){ that.headerColorChange(); },100);
        }
    }
    componentDidMount() {
        const { authorized } = this.props.state;

        if (this.props.changeColorOnScroll) {
            window.addEventListener("scroll", this.headerColorChange);
            this.headerColorChange();
        }

        if(authorized && AuthApi.hasAccessToken()){
            this.getNotifictions();
        }
    }
    componentDidUpdate(prevProps){
        const { hugsGenerated } = this.props.state;
        if(prevProps.state.hugsGenerated !== hugsGenerated && hugsGenerated){
            this.getNotifictions();
        }
    }
    headerColorChange() {
        const { color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            this.setState({color: changeColorOnScroll.color});
        } else {
            this.setState({color: color});
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener("scroll", this.headerColorChange);
        }
    }
    handleClick = (event) => {
        this.setState({
            popupOpen: true,
            anchorEl: event.currentTarget
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }
    handleClose = () => {
        this.setState({
            popupOpen: false,
            anchorEl: null
        })
    }
    handleNotificationsClick = (event) => {
        this.setState({
            notificationsOpen: true,
            anchorEl: event.currentTarget
        })
    }
    handleNotificationsClose = () => {
        this.setState({
            notificationsOpen: false,
            anchorEl: null
        })
    }
    getNotifictions(){
        const requestData = {
            limit: 50,
            page: 1
        }
        this.setState({
            notificationsLoading: true
        })
        const source = axios.CancelToken.source();
        Api.getNotifications(requestData, source).then(data => {
            this.setState({
                notificationsResponse: data.response,
                notificationsLoading: false,
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                notificationsLoading: false,
            })
        });
    }
    renderNotificationsItems(){
        const { notificationsResponse } = this.state;
        if(notificationsResponse === null){
            return null;
        }
        let items = [];
        notificationsResponse.map((item, key) => {
            items.push(
                <li className="sd-notification-item" key={key}>
                    <p dangerouslySetInnerHTML={{__html: item.body_content}}></p>
                </li>
            );
            return null;
        });
        return items;
    }
    logout(){
        this.setState({
            logout: true
        })
        const source = axios.CancelToken.source();
        const requestData = {}
        Api.revokeAccessToken(requestData, source).then(data => {
            AuthApi.logout();
            window.location.href = "/";
        }).catch(err => {
            AuthApi.logout();
            window.location.href = "/";
        });
    }
    onFormSubmit = (event) => {
        event.preventDefault();
        this.onLinkClick("/home?search="+this.state.searchValue);
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    onLinkClick(href){
        this.props.history.push(href);
        this.handleClose();
        if(href === '/home'){
            this.props.history.home = true;
        }else{
            this.props.history.home = false;
        }
    }
    render() {
        const { popupOpen, anchorEl, logout, notificationsOpen, notificationsLoading, searchValue } = this.state;
        const { links, brand, desktopLinks } = this.props;
        const { authorized, user } = this.props.state;

        let userFirstLetter = "";
        if(authorized){
            userFirstLetter = user.first_name.charAt(0);
        }
        const id = popupOpen ? 'simple-menu' : undefined;
        const notificationsId = popupOpen ? 'simple-popover' : undefined;

        return (
            <AppBar className={"appbar "+(authorized ? 'loggedin' : 'non-loggeding')}>
                <Toolbar className={"container"}>
                    <Link to={"/"} onClick={() => this.onLinkClick("/")} className={"brand"}>
                        <img src={logo} alt={brand} />
                    </Link>
                    <div className="sd-header-content">
                        <Hidden mdDown={!authorized} implementation="css" className={"hidden sd-left-menu"}>
                            {desktopLinks}
                        </Hidden>
                        {
                            authorized ?
                                <div className="sd-authorized-menu">
                                    <form onSubmit={this.onFormSubmit} className="sd-search-form">
                                        <div className="sd-mobile-search" onClick={()=> this.handleMobileSearch(true)}>
                                            <Search />
                                        </div>
                                        <div className={(this.state.mobileSearh ? 'active' : '')+' sd-search-bar'}>
                                            <Search />
                                            <input 
                                                type="text" 
                                                name="search" 
                                                value={searchValue}
                                                onChange={(e) => this.handleChange(e,'searchValue')}
                                            />
                                        </div>
                                    </form>
                                    <Hidden mdDown implementation="css" className={"hidden"}>
                                        <List className={"list"}>
                                            <ListItem className={"listItem dashboard-menu"}>
                                                <RouterLink to="/home" onClick={() => this.onLinkClick("/home")} className={"navLink about-us"}>
                                                    Dashboard
                                                </RouterLink>
                                            </ListItem>
                                            <ListItem className={"listItem"}>
                                                <RouterLink to="/about-us" onClick={() => this.onLinkClick("/about-us")} className={"navLink about-us"}>
                                                    About Us
                                                </RouterLink>
                                            </ListItem>
                                        </List>
                                    </Hidden>
                                    <div className="sd-notifications-section">
                                        <div className="sd-notifications-icon">
                                            <Notifications 
                                                onClick={this.handleNotificationsClick} 
                                                aria-describedby={notificationsId}
                                                color="inherit"
                                                aria-label="open popup"
                                                variant="contained"
                                            />
                                        </div>
                                        <Popover
                                            className="sd-notifications-dropdown"
                                            anchorEl={anchorEl}
                                            id={notificationsId}
                                            open={notificationsOpen}
                                            onClose={this.handleNotificationsClose}
                                            transformOrigin={{ 
                                                horizontal: 'right', 
                                                vertical: 'top' 
                                            }}
                                            anchorOrigin={{ 
                                                horizontal: 'right', 
                                                vertical: 'bottom' 
                                            }}
                                        >
                                            <h6>Notifications</h6>
                                            {
                                                notificationsLoading ? 
                                                    <LoaderComponent />
                                                :
                                                    <ul>
                                                        { this.renderNotificationsItems() }
                                                    </ul>
                                            }
                                        </Popover>
                                    </div>
                                    <div className="sd-user-profile-menu">
                                        <div className="sd-user-icon-name" onClick={this.handleClick} aria-describedby={id}>
                                            <IconButton
                                                className={"user-first-letter"}
                                                color="inherit"
                                                aria-label="open popup"
                                                variant="contained"
                                            >
                                                <span className="user-icon">
                                                    {
                                                        user.photo_url ?
                                                            <img src={user.photo_url} className="profile-picture" alt={user.title} />
                                                        :
                                                            userFirstLetter
                                                    }
                                                </span>
                                            </IconButton>
                                            <span className="sd-user-title">{ user.title }</span>
                                        </div>
                                        <Menu
                                            className="sd-profile-menu"
                                            anchorEl={anchorEl}
                                            id={id}
                                            open={popupOpen}
                                            onClose={this.handleClose}
                                            transformOrigin={{ 
                                                horizontal: 'right', 
                                                vertical: 'top' 
                                            }}
                                            anchorOrigin={{ 
                                                horizontal: 'right', 
                                                vertical: 'bottom' 
                                            }}
                                        >
                                            <h6>ACCOUNT</h6>
                                            <MenuItem onClick={() => this.onLinkClick("/settings")}>
                                                Settings
                                            </MenuItem>
                                            <MenuItem onClick={() => this.onLinkClick("/invite")}>
                                                Invite Friends
                                            </MenuItem>
                                            <Divider className="bigger-border" />
                                            <MenuItem>
                                                {
                                                    logout ?
                                                        <Button className={"button logout radius-30"}>
                                                            <LoaderComponent />
                                                        </Button>
                                                    :
                                                        <Button onClick={() => this.logout()} className={"button logout radius-30"}>
                                                            <Logout /> Log Out
                                                        </Button>
                                                }
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            :
                                <></>
                        }
                        <Hidden mdUp>
                            <IconButton
                                aria-label="open drawer"
                                onClick={this.handleDrawerToggle}
                                className="mobile-menu-toggle"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    </div>
                </Toolbar>
                <Hidden mdUp implementation="js">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.state.mobileOpen}
                        className={"drawerPaper sd-menu-drawer"}
                        onClose={this.handleDrawerToggle}
                    >
                        <IconButton
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                            className={"closeButtonDrawer"}
                        >
                            <Close />
                        </IconButton>
                        <div className={"appResponsive"}>{links}</div>
                    </Drawer>
                </Hidden>
            </AppBar>
        );
    }
}

Header.defaultProp = {
    color: "white",
    open: false,
    updateDrawer: function(){ },
};

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark",
        "gray"
    ]),
    links: PropTypes.node,
    desktopLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    open: PropTypes.bool,
    updateDrawer: PropTypes.func,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark",
            "gray"
        ]).isRequired
    })
};

export default connect(mapStateToProps)(Header);