import React, { Component } from "react";
import coverImg from "../../../assets/img/cover.png";
import noPhotoProfile from "../../../assets/img/nophoto_user_profile.png";
import {ModeEdit} from "@mui/icons-material";
import {Button} from "@mui/material";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import { Error, CameraAlt } from "@mui/icons-material";
import LoaderComponent from '../../components/Loader'
import AuthApi from '../../../assets/js/utils/Auth';

import "../../../assets/scss/userSettingsStyle.scss";

class Settings extends Component {
    constructor(props) {
        super(props);

        const { user } = this.props.state;
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: {
                ...values,
                old_password: '',
                password: ''
            },
            savingInfo: false,
            showError: false,
            errorMessage: "",
            uploading: false,
            coverUploading: false,
            success: false,
            showPasswordErrors: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            validation: {
                firstName: '',
                lastName: '',
                email: '',
                old_password: '',
                password: '',
                isValid: false
            },
        }

        this.fileUploadRef = React.createRef();
        this.coverUploadRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.props.state;
        if(!authorized && !AuthApi.hasAccessToken()){
            const location = this.props.history.location;

            const loginRequired = "/login?return="+encodeURIComponent(location.pathname+location.search);
            this.props.history.push(loginRequired);
            return;
        }
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    handlePhoneChange(e, name) {
        const { phone } = this.state.values;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^[+\d]+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = phone;
        }
        this.setState({
            values: {
                ...this.state.values,
                [name]: state[name]
            }
        });
    }
    saveUserInfo(e){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            savingInfo: true,
            showError: false
        });
        
        const { user } = this.props.state;
        let values = Object.assign({}, this.state.values);

        const source = axios.CancelToken.source();
        Api.updateUser(user.id, values, source).then((data) => {        
            this.setState({
                savingInfo: false, 
            });
            localStorage.setItem("forember_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.props.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    validateForm(){
        const { values } = this.state;

        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            old_password: "success",
            password: "success",
            isValid: true
        };
        if(values.first_name.length <= 2){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(values.last_name.length <= 2){
            validation.lastName = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(values.email.length <= 0 || !emailRegex.test(values.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(values.old_password.length > 0 || values.password.length > 0){
            const that = this;
            const check = PasswordMatch.check(values.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
                that.setState({
                    showPasswordErrors: true,
                    requireLowerletter: requireLowerletter,
                    requireUpperletter: requireUpperletter,
                    requireNumber: requireNumber,
                    requireSymbol: requireSymbol,
                    requireLength: requireLength
                });
            });
            
            if(values.old_password.length <= 0){
                validation.old_password = "error";
                validation.isValid = false;
            }
            if(values.password.length <= 0 || check === false){
                validation.password = "error";
                validation.isValid = false;
            }
        }else{
            this.setState({
                showPasswordErrors: false
            })
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    handleFileChange(e, name, type = 'profile_photo'){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);
        requestData.append('type', type);

        const source = axios.CancelToken.source();

        let stateObj = {
            [name]: photo,
            cancelToken: source,
            showError: false,
        };
        if(type === 'profile_photo'){
            stateObj['uploading'] = true;
        }else{
            stateObj['coverUploading'] = true;
        }

        this.setState(stateObj);

        const that = this;
        Api.uploadUserPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                uploading: false,
                coverUploading: false
            });
            localStorage.setItem("forember_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.props.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    coverUploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { showError, values, validation, showPasswordErrors, requireLength, requireLowerletter, requireNumber, requireSymbol, 
            requireUpperletter, savingInfo, uploading, coverUploading, success } = this.state;
        const { user } = this.props.state;

        return (
            <>
                <div className="sd-user-profile-settings-container">
                    <div className="sd-user-cover-container">
                        {
                            coverUploading ?
                                <LoaderComponent />
                            :
                                <></>
                        }
                        {
                            user.cover_url ?
                                <img src={user.cover_url} alt={ user.title } />
                            :
                                <img src={coverImg} alt={ user.title } />
                        }
                        <div className="container sd-cover-edit">
                            <div className="sd-change-cover-photo" onClick={() => this.coverUploadRef.current.click()}>
                                <ModeEdit />
                            </div>
                            <input type="file" onChange={(e) => this.handleFileChange(e,'photo', 'profile_cover_photo')} ref={this.coverUploadRef} accept={"image/*"} style={{display: "none"}} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="sd-user-profile-info">
                            <div className="sd-user-profile-icon">
                                {
                                    uploading === true ?
                                        <LoaderComponent />
                                    : success === true || user.photo_url_profile ?
                                        <img src={user.photo_url_profile} alt={ user.title } />
                                    :
                                        <img src={noPhotoProfile} alt={ user.title } />
                                }
                                {
                                    uploading === true ?
                                        <></>
                                    :
                                        <div className="sd-change-profile-photo" onClick={() => this.fileUploadRef.current.click()}>
                                            <CameraAlt />
                                        </div>
                                }
                                <input type="file" onChange={(e) => this.handleFileChange(e,'photo', 'profile_photo')} ref={this.fileUploadRef} accept={"image/*"} style={{display: "none"}} />
                            </div>
                            <div className="sd-username">
                                <h2>{ user.title }</h2>
                            </div>
                        </div>
                        <div className="sd-settins-form-wrapper">
                            <div className="sd-settins-form-container">
                                <h3>Settings</h3>
                                {
                                    showError ?
                                        <div className="sd-error-messages">
                                            { this.renderErrorMessages() }
                                        </div>
                                    :
                                    <></>
                                }
                                <form>
                                    <div className="elements-wrapper row-elements">
                                        <div className="elements-container">
                                            <input 
                                                className={validation.firstName === "error" ? 'error' : ''}
                                                type="text" 
                                                placeholder="First name" 
                                                value={values.first_name}
                                                onChange={(e) => this.handleChange(e,'first_name')}
                                            />
                                            {
                                                validation.firstName === "error" ?
                                                    <Error />
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className="elements-container">
                                            <input 
                                                className={validation.lastName === "error" ? 'error' : ''}
                                                type="text" 
                                                placeholder="Last name" 
                                                value={values.last_name}
                                                onChange={(e) => this.handleChange(e,'last_name')} 
                                            />
                                            {
                                                validation.lastName === "error" ?
                                                    <Error />
                                                :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="elements-wrapper row-elements">
                                        <div className="elements-container">
                                            <input 
                                                className={validation.email === "error" ? 'error' : ''}
                                                type="text" 
                                                placeholder="Email Address" 
                                                value={values.email}
                                                onChange={(e) => this.handleChange(e,'email')} 
                                            />
                                            {
                                                validation.email === "error" ?
                                                    <Error />
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className="elements-container">
                                            <input 
                                                type="text" 
                                                placeholder="Phone (+1XXXXXXXXXX)" 
                                                value={values.phone}
                                                onChange={(e) => this.handlePhoneChange(e,'phone')} 
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="elements-wrapper row-elements">
                                        <div className="elements-container">
                                            <input 
                                                className={validation.old_password === "error" ? 'error' : ''}
                                                type="password" 
                                                placeholder="Old Password" 
                                                onChange={(e) => this.handleChange(e,'old_password')}
                                                autoComplete="off"
                                            />
                                            {
                                                validation.old_password === "error" ?
                                                    <Error />
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className="elements-container">
                                            <input 
                                                className={validation.password === "error" ? 'error' : ''}
                                                type="password" 
                                                placeholder="New Password" 
                                                onChange={(e) => this.handleChange(e,'password')}
                                                autoComplete="off"
                                            />
                                            {
                                                validation.password === "error" ?
                                                    <Error />
                                                :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    {
                                        showPasswordErrors ?
                                            <div className={"password-errors"}>
                                                <div>
                                                    <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                        <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                        <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                    </div>
                                                    <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                        <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                        <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                    </div>
                                                    <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                        <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                        <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                    </div>
                                                    <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                        <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                        <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                    </div>
                                                    <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                        <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                        <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <></>
                                    }
                                </form>
                            </div>
                            <div className="sd-settings-buttons-wrapper">
                                {
                                    savingInfo ?
                                        <Button
                                            className={"button"}
                                        >
                                            <LoaderComponent />
                                        </Button>
                                    :
                                        <Button className={"button"} onClick={(e) => this.saveUserInfo(e)}>
                                            Save
                                        </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default connect(mapStateToProps)(Settings);