import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions } from "@mui/material";
import { Close } from "@mui/icons-material";
// import { ModeEdit } from "@mui/icons-material";
import LoaderComponent from '../Loader'

import "../../../assets/scss/deleteHugModalStyle.scss";

class DeleteHugModal extends React.Component {
    render() {
        const { saving, deleted, isHugDeleted } = this.props;
        let redirect = deleted ? true : false;
        return (
            <>
                <Dialog
                    className="sd-delete-hug-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose(false, redirect)}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        <p className={"modal-title"}>
                            {
                                isHugDeleted ?
                                    <>Do you wish to cancel the deletion of this hug?</>
                                : deleted ?
                                    <>This hug will be deleted in 3 days.</>
                                :
                                    <>Please review the options below:</>
                            }
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        {
                            deleted || isHugDeleted ?
                                <></>
                            :
                                <div className="sd-delete-hug-modal-content">
                                    <ul>
                                        <li><span>Edit Option:</span> Edit existing hug to provide a refreshed message for your loved one(s). Recommended.</li>
                                        <li><span>Delete Option:</span> Hug will be deleted in 3 days to allow notification to any hug recipients providing the opportunity to download.</li>
                                    </ul>                                    
                                    <p>Do you still wish to delete this hug?</p>
                                </div>
                        }
                        
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        {
                            saving ?
                                <LoaderComponent />
                            : deleted ?
                                <Button className="button radius-8" onClick={() => this.props.onClose(false, true)}>OK</Button>
                            :
                                <>
                                    <Button className="button radius-8" onClick={() => this.props.onSuccess()}>{this.props.ConfirmationButtonText}</Button>
                                    <Button className="button radius-8" onClick={() => this.props.onClose(false)}>No</Button>
                                </>
                        }
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}

DeleteHugModal.defaultProps = {
    ConfirmationButtonText: "Yes",
    saving: false
}

export default DeleteHugModal;