import React from 'react';
import { hydrate } from 'react-dom';
import ReactDOM from 'react-dom/client';
import configureStore from './store';
import App from './app/views/App';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

let store = configureStore();
const root = document.getElementById('root');
const rootElement = ReactDOM.createRoot(root);
if (root.hasChildNodes()) {
    hydrate(
        // <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>,
        // </React.StrictMode>,
        rootElement
    );
}else{
    rootElement.render(
        // <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>,
        // </React.StrictMode>,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: function(registration, serviceWorker){
        registration.update();
        serviceWorker.postMessage('SKIP_WAITING');
    },
    onSuccess: function(registration, serviceWorker){
        //console.log("Service Worker Installed");
    }
});