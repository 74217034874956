import React, { Component } from "react";
import { Button } from "@mui/material";
import { Error } from "@mui/icons-material";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../../components/Loader';
import SuccessModal from '../../components/Modal/SuccessModal';

import "../../../assets/scss/userInvite.scss";

class Invite extends Component {

    constructor(props){
        super(props);

        this.state = {
            invivations: "",
            saving: false,
            showError: true, 
            errorMessage: null,
            validation: {
                invivations: '',
                isValid: false
            },
            successModal: false
        };
    }
    isValid(){
        const { invivations } = this.state;

        let validation = {
            invivations: "success",
            isValid: true
        };
        if(invivations.length <= 2){
            validation.invivations = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    onSubmit(){
        if(!this.isValid()){
            return;
        }
        const { invivations } = this.state;
        const requestData = {
            invivations: invivations
        };
        this.setState({
            saving: true
        })
        const source = axios.CancelToken.source();
        Api.sendInvitation(requestData, source).then(data => {
            this.setState({
                invivations: "",
                saving: false
            });

            this.onSuccessModal(true);
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
            })
        });
    }
    handleChange(e, name){
        this.setState({
            [name]: e.target.value
        })
    }
    onSuccessModal(status = false){
        this.setState({
            successModal: status
        })
    }
    render() {
        const { invivations, validation, saving, successModal } = this.state;
        return (
            <div className="container">
                <div className="sd-invite-section">
                    <h3>Invite your loved one's to create hugs that lasts forever</h3>
                    <p>Add comma seperated emails addresses to invite your freinds and family so they can join and participate in creating forever hugs.</p>
                    <form>
                    <textarea 
                            className={validation.invivations === "error" ? 'error' : ''}
                            name="invivations" 
                            value={invivations} 
                            onChange={(e) => this.handleChange(e, "invivations")} 
                            placeholder="email1@gmail.com, email2@yahoo.com" 
                        />
                        {
                            validation.invivations === "error" ?
                                <Error />
                            :
                                <></>
                        }
                        <Button onClick={() => this.onSubmit()} size={"lg"} className={"button radius-30"}>
                            {
                                saving ?
                                    <LoaderComponent />
                                :
                                    "Invite"
                            }
                        </Button>
                    </form>
                </div>
                {
                    successModal ?
                        <SuccessModal
                            open={successModal}
                            title="Thank you. Your invitation has been sent."
                            buttonText="Close"
                            onClose={() => this.onSuccessModal(false)}
                            onButtonAction={() => this.onSuccessModal(false)}
                        />
                    :
                        <></>
                }
            </div>
        )
    }
};

export default Invite;
