import React, { Component } from "react";
import { matchPath } from 'react-router';
import { PlayArrow, ModeEdit, EmailOutlined, FavoriteOutlined, CalendarTodayOutlined } from "@mui/icons-material";
import Api from "../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../components/Loader';
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";
import { InlineShareButtons } from 'sharethis-reactjs';
import { Link as RouterLink } from "react-router-dom";
import HugReminderModal from "../components/Modal/HugReminderModal";
import EmailModal from "../components/Modal/EmailModal";
import CreatorEmailModal from "../components/Modal/CreatorEmailModal";
import AddRecipientModal from "../components/Modal/AddRecipientModal";
import SuccessModal from '../components/Modal/SuccessModal';
import { helper } from "../../assets/js/utils/Element";

import "../../assets/scss/userHugStyle.scss";

class Hug extends Component {
    constructor(props) {
        super(props);

        let match = matchPath({
            path: "/hug/:idHash/:dateHash",
        }, window.location.pathname);
        let idHash = null;
        let dateHash = null;
        if(match !== null && match.params.hasOwnProperty("idHash")){
            idHash = match.params.idHash;
        }
        if(match !== null && match.params.hasOwnProperty("dateHash")){
            dateHash = match.params.dateHash;
        }
        this.state = {
            idHash: idHash,
            dateHash: dateHash,
            response: null,
            emailModal: false,
            creatorEmailModal: false,
            canSendThanksEmail: true,
            addRecipientModal: false,
            emailSuccessModal: false
        };

        this.playButton = React.createRef();
        this.videoPlayer = React.createRef();
    }
    componentDidMount(){
        this.getUserHug();
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.response === null && this.state.response !== null){
            const reminderParam = decodeURIComponent(helper.getParam("reminder"));
            if(reminderParam && reminderParam === '1'){
                this.handleHugModal(true);
                this.props.history.replace({
                    reminder: '',
                })
            }
        }
    }
    getUserHug(){
        const { idHash, dateHash } = this.state;
        const { user } = this.props.state;

        this.setState({
            loading: true,
        })

        let requestData = {
            id: null,
            idHash: idHash,
            dateHash: dateHash,
            loggedIn: user.id ? 1 : 0
        }
        const source = axios.CancelToken.source();
        Api.getUserHug(requestData, source).then(data => {
            this.setState({
                response: data,
                loading: false,
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                loading: false,
            });
        });
    }
    onVideoPlay(){
        this.playButton.current.style.display = "none";
    }
    onVideoPause(){
        this.playButton.current.style.display = "";
    }
    isRecipient(){
        const { response } = this.state;
        const { user } = this.props.state;

        let isRecipient = false;
        response.recipients.map((item, key) => {
            if(item.user_id === user.id){
                isRecipient = true;
            }
            
            return null;
        });

        return isRecipient;
    }
    getShareUrl(){
        const { response } = this.state;
        let id = response.id;
        if(response.hasOwnProperty('hash') && response.hash !== ''){
            id = response.hash;
        }

        return window.location.origin+"/hug/"+id;
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    onEmailModal(status, name = 'emailModal', emailStatus = false){
        let stateObj = {};
        stateObj[name] = status;
        if(emailStatus === true){
            stateObj['canSendThanksEmail'] = false;
        }

        this.setState(stateObj);
    }
    getRecipentNames(){
        let hug = this.state.response;
        let hugRecipients = hug.recipients;

        let recipientNames = "";
        hugRecipients.map((item, key) => {
            recipientNames += item.first_name;

            if (key + 1 !== hugRecipients.length) {
                recipientNames += ", ";
            }
            
            return null;
        });

        return recipientNames;
    }
    onAddRecipientModal(status = false){
        let stateObj = {
            addRecipientModal: status
        };

        this.setState(stateObj);
    }
    onAddRecipients(recipients){
        const { response } = this.state;

        let hugObj = response;
        hugObj.recipients = [];
        recipients.map(recipient => {
            hugObj.recipients.push(recipient);

            return null;
        });

        this.setState({
            response: hugObj
        })
    }
    onSendEmail(hugResponse){
        const { response } = this.state;

        let hugObj = response;
        hugObj.hug_sent = hugResponse.hug_sent;
        hugObj.hug_sent_date = hugResponse.hug_sent_date;
        hugObj.hug_sent_date = hugResponse.hug_sent_date;
        hugObj.recipients = hugResponse.recipients;

        this.setState({
            addRecipientModal: false,
            response: hugObj
        }, () => {
            this.onSuccessModal(true);
        });
    }
    onSuccessModal(status = false){
        this.setState({
            emailSuccessModal: status
        })
    }
    handleHugModal(status){
        this.setState({
            hugReminderModal: status
        })
    }
    render(){
        const { loading, response, hugReminderModal, emailModal, creatorEmailModal, addRecipientModal, emailSuccessModal } = this.state;
        const { history } = this.props;
        const { user, authorized } = this.props.state;
        const location = this.props.history.location;

        return (
            <>
                <div className="sd-section-bg">
                    <div className="container">
                        {
                            !loading && response !== null ?
                                <div className="sd-user-hugpage">
                                    <div className="sd-hug-info">
                                        <div className="sd-hug-user-details">
                                            <div className="sd-hug-user hug-flex-items">
                                                <div className="sd-from-hug">
                                                    <span className="sd-background sd-from sd-created-by">Created By</span>
                                                    <img src={response.owner.photo_url} alt={response.owner.title} />
                                                    <span>
                                                        {
                                                            response.hasOwnProperty('from') && response.from.length > 0 ? 
                                                                response.from
                                                            :
                                                                response.owner.title
                                                        }
                                                    </span>
                                                </div>
                                                <div className="sd-to-hug">
                                                    <span className="sd-background sd-to">For</span>
                                                    <span>{ this.getRecipentNames() }</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sd-hug-note">
                                        <h4>Note :</h4>
                                        <p>{response.notes}</p>
                                    </div>
                                    <div className="sd-hug-card-container">
                                        <div className="sd-hug-card-content">
                                            <div className={"sd-hug-icons "+(user.id === response.owner_id ? 'owner' : '')}>
                                                {
                                                    authorized ?
                                                        <>
                                                            {
                                                                user.id === response.owner_id ?
                                                                    <>
                                                                        <div className="sd-email-icon" title="Send Hug Now" onClick={() => this.onAddRecipientModal(true)}>
                                                                            <EmailOutlined />Send Hug
                                                                        </div>
                                                                        <RouterLink className="sd-hug-view-edit" title="Edit Hug" to={"/hug/edit/"+response.id} onClick={() => this.onLinkClick("/hug/edit/"+response.id)} >
                                                                            <ModeEdit />Edit Hug
                                                                        </RouterLink>
                                                                    </>
                                                                : response.canSendThanksEmail ?
                                                                    <>
                                                                        <div className="sd-hug-reminder">
                                                                            <div className="sd-hug-reminder-icon" title="Add Reminder" onClick={()=> this.handleHugModal(true)}>
                                                                                <CalendarTodayOutlined />Add Reminder
                                                                            </div>
                                                                            <div className="sd-hug-reminder-note"><p>Schedule a reminder so your hug is automatically sent to you on special dates</p></div>
                                                                        </div>
                                                                        <div className="sd-thank-email-icon" title="Send Thanks" onClick={() => this.onEmailModal(true, 'creatorEmailModal')}>
                                                                            <FavoriteOutlined />Send Thanks
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </>
                                                    :
                                                        <div className="sd-hug-reminder">
                                                            <div className="sd-hug-reminder-icon" title="Add Reminder" onClick={()=> this.onLinkClick("/login?return="+encodeURIComponent(location.pathname+location.search)+"&reminder=1")}>
                                                                <CalendarTodayOutlined />Add Reminder
                                                            </div>
                                                            <div className="sd-hug-reminder-note"><p>Schedule a reminder so your hug is automatically sent to you on special dates</p></div>
                                                        </div>
                                                }
                                                <div className="sd-hug-socialshare" title="Share Hug" >
                                                    <InlineShareButtons
                                                        config={{
                                                            color: 'social',
                                                            enabled: true,
                                                            radius: 5, 
                                                            url: this.getShareUrl(),
                                                            title: 'I used forember to spread love with forever hugs.',
                                                            labels: 'cta',
                                                            networks: [
                                                                'sharethis',
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                                <div className="sd-hug-reminder sd-create-free-hug">
                                                    <div className="sd-hug-reminder-icon" title="Create Free Hug" onClick={() => this.onLinkClick("/create-hug")}>
                                                        Create Free Hug
                                                    </div>
                                                    <div className="sd-hug-reminder-note">
                                                        <p>Enjoying your hug? Join the mission to spread love by creating your free hug today!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="play-icon" ref={this.playButton}>
                                                <div className="play-icon-circle">
                                                    <PlayArrow />
                                                </div>
                                            </div>
                                            {
                                                response.video_url.length > 0 ?
                                                    <video controls poster={response.photo_url} onPause={() => this.onVideoPause()} onPlay={() => this.onVideoPlay()} ref={this.videoPlayer}>
                                                        <source src={response.video_url} />
                                                    </video>
                                                :
                                                <img src={response.photo_url} alt="video_img" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            :
                                !loading && (response === null || response.length <= 0) ?
                                    <div className="sd-hug-not-found">
                                        <span>Unfortunately this hug is not found in the system.</span>
                                    </div>
                                :
                                null
                        }
                        {
                            loading ?
                                <div className="sd-hug-loader">
                                    <LoaderComponent />
                                </div>
                            :
                            null
                        }
                    </div>
                </div>
                {
                    hugReminderModal ?
                        <HugReminderModal 
                            open={hugReminderModal}
                            onClose = {(status) => this.handleHugModal(status)}
                            id={response.id}
                            history={history}
                            canSendThanksEmail={response.canSendThanksEmail}
                        />
                    :
                        <></>
                }
                {
                    emailModal ? 
                        <EmailModal
                            open={emailModal}
                            onClose = {() => this.onEmailModal(false)}
                            onAddRecipient = {() => this.onAddRecipientModal(true)}
                            onSendEmail = {(hugResponse) => this.onSendEmail(hugResponse)}
                            hug={response}
                            history={history}
                        />
                    :
                        <></>
                }
                {
                    creatorEmailModal ?
                        <CreatorEmailModal
                            open={creatorEmailModal}
                            onClose = {(emailStatus) => this.onEmailModal(false, 'creatorEmailModal', emailStatus)}
                            hug={response}
                            history={history}
                        />
                    :
                        <></>
                }
                {
                    addRecipientModal ?
                        <AddRecipientModal
                            open={addRecipientModal}
                            onClose={() => this.onAddRecipientModal(false)}
                            hug={response}
                            onAddRecipients={(recipients) => this.onAddRecipients(recipients)}
                            onSendEmail = {(hugResponse) => this.onSendEmail(hugResponse)}
                            history={history}
                        />
                    :
                        <></>
                }
                {
                    emailSuccessModal ?
                        <SuccessModal
                            open={emailSuccessModal}
                            title="Your hug has been sent."
                            desc="Thank you for spreading love with forever hugs!"
                            buttonText="Close"
                            onClose={() => this.onSuccessModal(false)}
                            onButtonAction={() => this.onSuccessModal(false)}
                        />
                    :
                        <></>
                }
            </>
        );
    }
};

export default connect(mapStateToProps)(Hug);