import React, { Component } from "react";
import { matchPath } from 'react-router';
import { PlayArrow } from "@mui/icons-material";
import Api from "../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../components/Loader';
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";

import "../../assets/scss/userHugStyle.scss";

class HugPublic extends Component {
    constructor(props) {
        super(props);

        let match = matchPath({
            path: "/hug/public/:id",
        }, window.location.pathname);
        let id = null;
        if(match !== null && match.params.hasOwnProperty("id")){
            id = match.params.id;
        }
        this.state = {
            id: id,
            response: null
        };

        this.playButton = React.createRef();
        this.videoPlayer = React.createRef();
    }
    componentDidMount(){
        this.getUserHug();
    }
    getUserHug(){
        const { id } = this.state;

        this.setState({
            loading: true,
        })

        let requestData = {
            id: id,
            loggedIn: 1
        }
        const source = axios.CancelToken.source();
        Api.getHug(requestData, source).then(data => {
            this.setState({
                response: data,
                loading: false,
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                loading: false,
            });
        });
    }
    onVideoPlay(){
        this.playButton.current.style.display = "none";
    }
    onVideoPause(){
        this.playButton.current.style.display = "";
    }
    getRecipentNames(){
        let hug = this.state.response;
        let hugRecipients = hug.recipients;

        let recipientNames = "";
        hugRecipients.map((item, key) => {
            recipientNames += item.first_name;

            if (key + 1 !== hugRecipients.length) {
                recipientNames += ", ";
            }
            
            return null;
        });

        return recipientNames;
    }
    render(){
        const { loading, response } = this.state;
        
        return (
            <>
                <div className="sd-section-bg">
                    <div className="container">
                        {
                            !loading && response !== null ?
                                <div className="sd-user-hugpage">
                                    <div className="sd-hug-info">
                                        <div className="sd-hug-user-details">
                                            <div className="sd-hug-user hug-flex-items">
                                                <div className="sd-from-hug">
                                                    <span className="sd-background sd-from sd-created-by">Created By</span>
                                                    <img src={response.owner.photo_url} alt={response.owner.title} />
                                                    <span>
                                                        {
                                                            response.hasOwnProperty('from') && response.from.length > 0 ? 
                                                                response.from
                                                            :
                                                                response.owner.title
                                                        }
                                                    </span>
                                                </div>
                                                <div className="sd-to-hug">
                                                    <span className="sd-background sd-to">For</span>
                                                    <span>{ this.getRecipentNames() }</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sd-hug-card-container">
                                        <div className="sd-hug-card-content">
                                            <div className="play-icon" ref={this.playButton}>
                                                <div className="play-icon-circle">
                                                    <PlayArrow />
                                                </div>
                                            </div>
                                            {
                                                response.video_url.length > 0 ?
                                                    <video controls poster={response.photo_url} onPause={() => this.onVideoPause()} onPlay={() => this.onVideoPlay()} ref={this.videoPlayer}>
                                                        <source src={response.video_url} />
                                                    </video>
                                                :
                                                <img src={response.photo_url} alt="video_img" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            :
                                !loading && (response === null || response.length <= 0) ?
                                    <div className="sd-hug-not-found">
                                        <span>{ this.state.errorMessage }</span>
                                    </div>
                                :
                                null
                        }
                        {
                            loading ?
                                <div className="sd-hug-loader">
                                    <LoaderComponent />
                                </div>
                            :
                            null
                        }
                    </div>
                </div>
            </>
        );
    }
};

export default connect(mapStateToProps)(HugPublic);