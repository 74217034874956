import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";

import "../../assets/scss/privacyTermsStyle.scss";

class Terms extends Component {
    componentDidMount(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    render() {
        return (
            <>
                <div className="container">
                    <div className='sd-terms-privacy-container'>
                        <h3 className="two-one-color">TERMS OF USE</h3>
                        <h4 className="two-one-color">Last Modified:  March 2023</h4>
                        <h4 className="two-one-color">Welcome to Forember!</h4>
                        <p>Thanks for using Forember. These terms of use regulate and controls any access and use of Forember website <RouterLink className="not-blue" to="/" onClick={() => this.onLinkClick("/")}>www.forember.com</RouterLink> (the “Website”) and its services owned and operated by Forember LLC (“we”, “us”, “our”). When any person (each a “user,” member,” “you,” “your,” “their,” “<em>them</em>,” etc.) registers, subscribe, access, or uses our website, deemed to be their sole acceptance of these terms of use and our privacy policy. Please read these terms of use carefully before you access or use our website and its services. By accessing and using our website or its services, you agree to comply fully and be bound by the following terms of use. Please read these terms of use carefully before accessing and using our website and its services. If you do not agree with these terms, including changes to them, do not access or use our website and its services. We reserve the right to update, change or replace any part of these terms of use by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website after posting any changes constitutes acceptance of those changes.</p>
                        <h4>GENERAL DISCLAIMER: </h4>
                        <p>You hereby acknowledge and agree that we offer our website and its services “as is” and “as available”. Under no circumstances will we be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of our website and its services. If you obtain this website and its services or any information contained therein in any other place whose nature and we do not control availability, trusting such source or information will be at your own risk. You understand and agree that, to the fullest extent permitted by law, we disclaim all warranties, representations, and endorsements, express or implied, concerning the website, including, without limitation, implied warranties of title, merchantability, non-infringement and fitness for a particular purpose. You assume all responsibility and risk concerning using our website and its services or accessing any content or materials published on our website and community. We will not be liable or responsible for any computer viruses, bugs, malicious code, or other harmful components, delays, inaccuracies, errors, or omissions, or the accuracy, completeness, reliability, or usefulness of the information published, disclosed, or accessed through the website and its services. Under no circumstances will we be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of our website or its services. In our sole discretion, we reserve the right to reject your access to all or any part of our website and its services at any time, for any or no reason, with or without prior notice, and without liability.</p>
                        <h4>AVAILABILITY, SECURITY ACCURACY &amp; OF OUR WEBSITE AND ITS SERVICES</h4>
                        <p>We make no warranty that your access to our website and its services will be uninterrupted, timely, or error-free. Due to the nature of the internet, this cannot be guaranteed. Also, we may occasionally need to repair, maintain or introduce new facilities and functions. Access to the website and its services may be suspended or withdrawn to or from you personally or all users temporarily or permanently at any time and without notice. We may also impose restrictions on the length and manner of usage of any part of our website and its services for any reason. If we impose restrictions on you personally, you must not attempt to use the website and its services under any other name or user or on any other computer, mobile, or similar device. We do not warrant that our website and its services will be compatible with all hardware and software which you may use. We shall not be liable for damage to, viruses or other codes that may affect any device, software, data, or other device or property as a result of your access to or use of our website and its services or you are obtaining any material from, or as a result of using it. We shall also not be liable for the actions of third parties. We make no representation or warranty, express or implied, that information and materials on the website and its services are correct, complete, accurate, up-to-date, fit for a particular purpose, and free from errors or omissions. To the extent permitted by law, we do not accept any liability for any errors or omissions. However, nothing shall affect any obligation we may have under any contract to provide you with products and services.</p>
                        <h4>USE OF OUR WEBSITE AND ITS SERVICES</h4>
                        <ul>
                            <li><b className="underline">Services we are offering: </b> The Forember website and its services are specifically designed for the individual or user, enabling them to create, share, and schedule the delivery of forever hugs, which is a short digital message sent to loved ones (the “Services”)</li>
                            <li><b className="underline">Eligibility: </b>To join our membership program, you must be at least 13 years old or you have the age of majority following your local law. In its sole discretion, we reserve the right to deny any registration or subscription of anyone at any time and for any reason whatsoever. With parental consent, any individual under these age limits may sign up or join our services, provide any personal data to us, or otherwise submit personal data through our website and its services. A parent or guardian will be responsible for any activities of a Minor on the website and its services. Again, in its sole discretion, we reserve the right to deny any order of anyone at any time and for any reason whatsoever. You certify that you are thirteen (13) years of age and agree to provide true, accurate, current, and complete information when prompted for such information.</li>
                            <li><b className="underline">Registration or Sign Up: </b> You may not require signing up or creating an account with us to visit our website and its services and peruse its general contents. But to use our services, you must need to create an account by providing your First Name, Last Name, Email Address, Password, etc. Upon registration or sign-up, we may send you an email or phone verification code to verify your account and access your member profile page. We hereby disclaim, and you release us from any and all liability arising from any unauthorized registration and any related claims, losses, or damages alleged arising from or relating to any unauthorized user registration and/or collection of personal information in connection with an unauthorized registration. You agree that you will provide complete, current, and accurate information about yourself as requested and that you will keep that information up to date.</li>
                            <li><b className="underline">Account and Password Usage: </b>It's important that the information associated with your account must be accurate and up-to-date. You are fully responsible for maintaining the confidentiality and security of any and all actions that take place while using your account, and you must notify our support center right away of any actual or suspected scam, loss, theft, or unauthorized use of your account or account password. Under no circumstances you may share your account information with any other user or person. We are not responsible for any loss that results from the unauthorized use of your email and password, with or without your knowledge. You will immediately notify us of any unauthorized use of your account on the website.</li>
                            <li><b className="underline">User Communications: </b> By signing up or registering an account with us, you are opting to receive communications regarding our services, including newsletters, special offers, and blogs. You may opt out of these communications by following the “unsubscribe” link at the bottom of any email. In addition, we may use email, live chat plugins, and other forms of communication systems as needed to communicate with our users.</li>
                            <li><b className="underline">International Users: </b> The Service is controlled, operated, and administered by Forember Inc. from our offices within the United States. If you access the service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use our website and its services from any country or in any manner prohibited by any applicable laws, restrictions, or regulations.</li>
                            <li><b className="underline">Termination of Access: </b> We may decide to suspend or terminate your access to our website and its services at any time without prior notice for any reason. Common reasons include improper use of our website and its services, misrepresentations, fraudulent activity, and activity that violate these terms of use and any provision of our privacy policy.</li>
                        </ul>
                        <h4>SUBSCRIPTION PRICING, PAYMENT, AND REFUND</h4>
                        <ul>
                            <li><b className="underline">Subscriptions:</b> We are offering monthly, yearly, or other subscription packages and payment terms as stated on our website, and the such subscribed package will be subject to the following
                                <ul>
                                    <li><b className="underline">“Monthly” </b> subscriptions will be charged each month on the same day as the day of the month when the subscribed package was first activated. </li>
                                    <li><b className="underline">“Yearly” </b>subscriptions will be charged each year on the same day and month as the day and month when the subscribed package was first activated.</li>
                                    <li><b className="underline">“Others,” </b>as stated on our website</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Your payment for any subscription will be charged in advance monthly or yearly or other terms to your provided payment method. </p>
                        <ul>
                            <li><b className="underline">Pricing:</b> The service fees or price will be visible in USD and may include tax/VAT (gross price). Our prices are subject to change, and we will provide you with reasonable notice of any such changes. When you subscribe to any package, the price will be clarified during the order process. You agree to pay the price that is stated at the time of your order, as well as any applicable taxes. You also agree to the billing frequency stated during your order.</li>
                            <li><b className="underline">Payments:</b> All payments will be handled through the third-party secure gateway system through our website or otherwise. All payments to the site must be made using means of payment that we may approve from time to time. In countries/states where it applies, we currently require payments to be made by Stripe Payment Gateway. All payments must be made from a payment source on which you are the named account holder. The subscription or payment amount may include taxes or VATs and any other amounts which will be described on our website. You will be charged in advance for all payments or subscriptions. You waive your right to dispute any payment, and you will bear all costs. Any attempt to defraud us through the use of credit cards or other methods of payment or any failure by you to honor charges or requests for payment will result in immediate termination of your account and civil and/or criminal prosecution.</li>
                        </ul>
                        <p>In the case of suspected or fraudulent payment, including the use of stolen credentials by anyone, or any other fraudulent activity, we reserve the right to block the user account. If your payment method cannot verify or is found invalid, we may suspend or cancel your subscription or restrict your access to our website and its services automatically. We will reserve the right to refuse any subscription or payments from our users at our sole discretion. When you purchase access to any of our Services, you purchase a non-transferable, non-exclusive right to access the information. You may not publish or share the Digital Products or your login details with anyone else.</p>
                        <ul>
                            <li><b className="underline">Renewals and Cancellations: </b>Each subscription will renew in advance either on an annual basis or monthly basis, or other terms basis (as stated on our website) unless canceled in accordance with this section. When we renew your subscription, we will use the payment method currently associated with your account. We may notify you before impending renewals, and you expressly agree to waive the application of any law regarding our auto-renewal policy. You may cancel your subscription’s renewal through your account page or by contacting our member support team. You acknowledge and agree that your subscription is continuous until you cancel it, or we suspend your access to our services following these terms or as otherwise stated when you subscribe to our services. When you cancel your subscription, you acknowledge and agree to cancel only future charges associated with your subscription.</li>
                            <li><b className="underline">Refunds Policy: </b> The Forember team wants you to be completely happy with any product or service you purchase from us. Please let us know via our Contact Us page if you have any questions, concerns, or problems. If you purchased any Forember products or services and are not completely satisfied, we would love to hear from you and value your feedback. While it remains at Forember’s discretion to grant a refund, we will work hard to make you happy.</li>
                            <li><b className="underline">Termination or Suspension: </b> We also reserve the right to suspend or terminate your subscription for any reason, with or without notice or further obligation. You will not be entitled to a refund in these circumstances. You will not receive a refund if any or all of our digital products are temporarily unavailable. If your account or access to the website or its services are suspended or terminated, no refund will be offered; however, your account will be canceled, and no further charges will be incurred.</li>
                        </ul>
                        <h4>GRANT OF LICENSE TO USE OUR WEBSITE AND ITS SERVICES</h4>
                        <p>We grant you a personal, limited, revocable, non-exclusive, and non-transferable license to use the website and its services. This license extends only to the non-commercial creation, storage, and viewing of digital images and other media items on the website and only according to these terms of use. This license is exclusive to you, and you may not sublicense the use of the Sites or provide, disclose, distribute, transfer, or otherwise make available the website and its services to any individuals or entities that Forember has not specifically authorized to receive access to your account on the Sites. Forember.com expressly retains all ownership rights, title, and interest in and to all aspects of the Services and the Sites, including, but not limited to, all current and future patents, copyrights, trademarks, trade secrets, know-how, and other proprietary rights included or embodied in the website. You may not modify the Sites, create derivative works of the Sites, reverse engineer, reverse assemble, or do any other operation with the Sites that would reveal any source code, trade secrets, know-how, or other proprietary information. This license shall not be construed or interpreted as granting or providing rights to you to use, reproduce, modify, distribute, perform, display, possess, or control the source code or any other aspect of the website. You may not remove or modify any notice of confidentiality, trade secret, trademark, or copyright encoded or embodied in the Sites or displayed by, on, or on the website. You may use the Sites and the Services only while these Terms remain in effect. Under no circumstances shall you have any rights in or to the website or the Services after any termination or expiration of this agreement for any reason.</p>
                        <h4>USER CONTENT&nbsp;DISCLAIMER:</h4>
                        <p>You alone are responsible for any communication, message, and/or other content that you post, upload, submit, transmit, or share with Forember.com on this website, by electronic mail or otherwise, including but not limited to any data, questions, comments, suggestions or the like (collectively “Services Content”). By transmitting or posting any Service Content, you represent and warrant that such Service Content is your original work and will not infringe or violate any copyright, trademark, trade secret, rights of privacy, rights of publicity, or any other applicable laws. Forember.com does not endorse or sponsor any such Services Content submitted by you or other website users. Subject to the terms of use set forth in our <RouterLink to="/privacy-policy"  onClick={() => this.onLinkClick("/privacy-policy")}>Privacy Policy</RouterLink>, all Service Content will be treated as non-confidential and non-proprietary information. Accordingly, please do not post, upload, submit, or share any Service Content you wish to be kept confidential or for which you expect compensation, acknowledgment, or attribution.</p>
                        <p>You understand that all content, whether publicly posted or privately transmitted, is the sole responsibility of the person from which such content originated. This means that you, not Forember, are entirely responsible for all content you upload, post, email, transmit or otherwise make available via the service. Forember does not control the content posted via the service and, as such, does not guarantee the accuracy, integrity, or quality of such content. You understand that by using the service, you may be exposed to content that is offensive, indecent, or objectionable. Under no circumstances will forember.com be liable in any way for any Content, including, but not limited to, for any errors or omissions in any Content or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via the service. You may not obtain or seek to obtain access to materials on the Sites for which your access is not authorized, including materials belonging to other users. Any attempt to circumvent the Sites’ firewalls or other security devices violates this agreement. You agree to indemnify and hold Forember harmless for violating this provision.</p>
                        <h4>DISCLAIMER OF RESPONSIBILITY FOR CONTENT/ MATERIAL</h4>
                        <p>You acknowledge and agree that we do not control user-generated content or material submitted to the Services and disclaim any responsibility for such material. We specifically disclaim any duty, obligation, or responsibility to review, screen, refuse to post, remove, or edit any such content or other material. We do not represent or warrant that any other content or information accessible via the Services is accurate, complete, or current. Price and availability of information are subject to change without notice. We assume no responsibility or liability for any errors or omissions in the content of the Services.</p>
                        <h4>USER'S GRANT OF RIGHTS TO FOREMBER</h4>
                        <p>You grant us and related entities a royalty-free, perpetual, irrevocable, non-exclusive right and license</p>
                        <ul>
                            <li>To use, copy, modify, display, archive, store, publish, transmit, perform, distribute, reproduce, and create derivative works from all material you provide to us in any form, media, software or technology of any kind now existing or developed in the future. In addition, without limiting the generality of the previous sentence, you authorize us to include the material you provide in a searchable format that may be accessed by users of the Services and other websites.</li>
                            <li>To use your name and any other information you provide in connection with the use, reproduction, or distribution of such material.</li>
                            <li>To use the material and any facts, ideas, concepts, know-how, or techniques (“Information”) contained in any Material or communication you send to us for any purpose whatsoever, including but not limited to developing, manufacturing, promoting, and/or marketing products and services.</li>
                        </ul>
                        <p>You grant all rights described in this paragraph in consideration of your use of our website and its Services without the need for additional compensation of any sort to you. We do not claim ownership of the material you submit to the Services.</p>
                        <h4>USER CODE OF CONDUCT AND PROHIBITED ACTIVITIES</h4>
                        <p>To use our website and its services, you must always behave in a peaceful, civil, prudent, and respectful manner. You do not, or attempt to do, anything that is illegal; anything prohibited by any laws which apply to our website and its services or which applies to you or your use of our website and its services or any other user of our website, anything which we would consider inappropriate; or anything which might bring our user or us into disrepute. Moreover, you will not, and it is strictly prohibited to:</p>
                        <ul>
                            <li>Act in a specious manner by, among other things, impersonating any person, Distributing “spam”;</li>
                            <li>Promote information that is false or misleading, or promote illegal activities or conduct that is defamatory, libelous, or otherwise objectionable;</li>
                            <li>“frame” or “mirror” any part of our website;</li>
                            <li>modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or otherwise disassemble any portion of our website or its content or any software used on or for the website, or motivate others to do so;</li>
                            <li>Post, submit, use, transmit, or distribute, directly or indirectly (e.g., screen scrape) in any manner or media any content or information obtained from our website in connection with your use of the service following these terms and conditions;</li>
                            <li>Post any content or comment which is offensive to the online community, influences racism, influence gender discrimination, hatred, the physical harm of any kind, or is otherwise objectionable or non-acceptable against any group, community, or individual;</li>
                            <li>Post any content or information that you know is false or misleading or promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory, or libelous;</li>
                            <li>post any content which displays pornographic or sexually explicit material of any kind or exploits people sexually or violently;</li>
                            <li>Collect information about another user which may prohibit and suspicious in nature;</li>
                            <li>Advertise or solicit other's copyright information;</li>
                            <li>Promote fraudulent schemes, get-rich-quick schemes, online gaming and gambling, cash gifting, work-from-home businesses, or any other dubious money-making ventures;</li>
                            <li>Take part in any activity that in any way deem to be void or illegal under any law;</li>
                        </ul>
                        <p>We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting such information or materials. You agree that we may (but are not obligated to) filter and/or delete any Service Content that violates these terms of use.</p>
                        <h4>INTELLECTUAL PROPERTY RIGHTS OR COPYRIGHT</h4>
                        <p>We will aggressively enforce our intellectual property rights to the full extent of the law. All content, including but not limited to logo, image, trademark, service mark, design, icon, graphic, video, content, courses material, software, and all other information or materials (the “content,” “materials”) used and published on our website are legally owned by Forember Inc. and protected by The Copyright Act of 1976, Digital Millennium Copyright Act (DMCA) 1998 and other applicable states and federal intellectual property law of the United States. No Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without our permission.</p>
                        <p>You may download or print a copy of any portion of the content from our website only for personal or non-commercial, or educational use. You may not republish any of our content on any other place on the internet or extranet or incorporate the information in any other database or compilation. Any other use of the content is strictly prohibited. Any personal or commercial use of any content, image, design, logo, video, trademark, service mark, or any other information inserted in this website is strictly prohibited by any unauthorized use. Any unauthorized use of such content, image, trademark, service mark, logo, icon, graphic, software, and all other information that appeared on our website<strong>, </strong>will deem to be a violation of<strong>&nbsp; </strong>The Copyright Act of 1976, Digital Millennium Copyright Act (DMCA), 1998, other applicable federal or states law of the USA and international intellectual property law.</p>
                        <h4>PRIVACY POLICY</h4>
                        <p>You can view our privacy policy, which is linked at the bottom of every page. By using the website and its services, all the policy concerning you or any of our users will be conducted by our privacy policy inserted at the bottom of our website, or you can find here <RouterLink to="/privacy-policy"  onClick={() => this.onLinkClick("/privacy-policy")}>https://forember.com/privacy-policy</RouterLink></p>
                        <h4>THIRD-PARTY WEBSITES / ADVERTISEMENTS: </h4>
                        <p>Our website may contain ads and links to other websites. We assume no responsibility for the content or functionality of any non-forember.com website to which we provide a link. We have no control over and accept no responsibility for the content of any website or software to which a link from the website exists unless we are the provider of those linked websites or Software. Such linked websites and software is provided “as is” for your convenience only with no warranty, express or implied, for the information provided within them. The terms of use and privacy policies of those third-party websites and software will apply to your use of those websites and software and any orders you make for goods and services via such websites and software. Suppose you have any queries, concerns, or complaints about such third-party websites or software (including, but not limited to, queries, concerns, or complaints relating to services, subscription of the services, products, orders for products, faulty products, and refunds). In that case, you must direct them to the operator of that third-party website or software. You are solely responsible for adequate protection and backup of the data and equipment used with any software downloaded through the website. In the event of any express inconsistency between these terms and those contained in any license agreement with a software vendor, the Terms (i.e., the terms contained herein) are paramount.</p>
                        <h4>REPRESENTATIONS AND WARRANTIES</h4>
                        <p>You represent and warrant that (a) your use of our website and its services will strictly be following these terms of use, including all applicable laws and regulations without limitation any local laws or regulations in your country, state, city, or other governmental areas, regarding any online conduct and your use of our website and its services will not infringe or misappropriate the trademark, copyright or other intellectual property rights of any third party. To the maximum extent permitted by law, we make no warranty of any kind as to the services resulting from there, whether express, implied, statutory, or otherwise, and disclaim all implied warranties, including merchantability, fitness for a particular purpose, or non-infringement.</p>
                        <h4>LIMITATION OF LIABILITY</h4>
                        <p>You acknowledge and agree that your access to and use of the website and its services is at your own risk. All direct or indirect risk related to using our website and its services is borne entirely by you. Accordingly, we shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to special, direct, indirect, incidental, punitive, or consequential damages of any kind resulting from the use or the inability to use our website and its services. With the access and use of our website and its services or any of its services, you hereby also acknowledge and agree that.</p>
                        <ul>
                            <li>We reserve the right to restrict or limit its access or visibility to any of our users at any time;</li>
                            <li>Our liability will be limited to you or any other person for any direct, indirect, or special damages or losses resulting from the use or unable to use our website and its services or any content or services therein;</li>
                            <li>We are not responsible or liable for any threatening, defamatory, obscene, offensive, or illegal contents or conduct of any other parties or any infringement of any other’s rights, including copyrights. If you are dissatisfied with any of the terms of this terms of use document or any other policy outlined in our website and its services, the sole and exclusive remedy available to you is to discontinue using our website and its services;</li>
                            <li>In no event will we be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation, lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use, including, but not limited to, any errors or omissions in any content of our website and its services;</li>
                            <li>In our sole discretion, we reserve the right to investigate and take appropriate legal action against anyone who violates our terms of use and privacy policy.</li>
                        </ul>
                        <p>TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, NEITHER WE NOR ANY OF OUR DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS, AGENTS, REPRESENTATIVES, OR AFFILIATES (THE ” FOREMBER.COM PARTIES”) SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS, PROFITS, USE OR DATA), WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE AND STRICT LIABILITY) OR ANY OTHER LEGAL THEORY, EVEN IF THE FOREMBER.COM PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR RELATING IN ANY WAY TO OUR PROVISION OF (OR FAILURE TO PROVIDE) PRODUCTS OR SERVICES, OR FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR SUBMISSIONS OR DATA, EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. YOU SPECIFICALLY ACKNOWLEDGE THAT THE FOREMBER.COM PARTIES ARE NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU. FURTHERMORE, THE FOREMBER.COM PARTIES WILL HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY THIRD-PARTY CONTENT UPLOADED. YOU HAVE THE SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH PRODUCTS TO OBTAIN A REFUND, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH SERVICES IS TO STOP USING THE SERVICES. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE MAXIMUM LIABILITY OF THE FOREMBER.COM PARTIES ARISING OUT OF OR RELATING IN ANY WAY TO OUR PROVISION OF (OR FAILURE TO PROVIDE) PRODUCTS OR SERVICES SHALL BE THE ACTUAL PRICE PAID, THEREFORE, BY YOU.</p>
                        <h4>GOVERNING LAW AND JURISDICTION </h4>
                        <p>These Terms of use are governed by and have been construed in accordance with the laws of the state of Wisconsin, United States, without regard to conflicts of law provisions thereof and without regard to the other States and Federal law of the United States which and where it applies and needs to enforce these terms of use. Suppose any part of these terms of use is deemed unlawful, void, or unenforceable. In that case, that part will be deemed severable and will not affect the validity and enforceability of the remaining parts. You hereby consent to the exclusive jurisdiction of Wisconsin, USA, in respect of any disputes arising in connection with the website, any relevant terms of use, policies, and notices, or any matter related to or in connection in addition to that.</p>
                        <h4>DISPUTES MANAGEMENT</h4>
                        <p>If there is any dispute, claim, or complaint about or involving the use of our website and its services will be managed by our support team first. However, if the matters progress to a legal stage, our attorney will arrange a meeting with the user, and it may be subject to settlement by binding arbitration as set forth below.</p>
                        <h4>ARBITRATION, CLASS ACTION WAIVER:</h4>
                        <p>Subject to these terms of use, you hereby agree not to exercise your rights to participate in a class action or representative action for any claim or dispute arising out of or relating to the use of our website and its services. You acknowledged and agreed that you would not join or consolidate claims by or against other parties or otherwise participate in any claim as a class representative or class member.</p>
                        <p>You agree that any dispute between the parties hereto shall be settled by arbitration administered by the American Arbitration Association. Accordingly, the parties agree to submit such dispute to binding arbitration under the Commercial Arbitration Rules of the American Arbitration Association before a single arbitrator with relevant knowledge in the City of Madison, Wisconsin. The arbitrator must be a retired judge who served in Wisconsin’s state or federal courts and mutually approved by the parties in writing. The aggrieved party shall file a notice to arbitrate with the American Arbitration Association, whose rules and procedures govern such arbitration. The language of the arbitration shall be English. The arbitrator’s award may be entered in any court of competent jurisdiction for enforcement. The cost of any such arbitration and the cost of the arbitrator shall be split equally between the parties. However, it is agreed that each party shall bear its costs and expenses, including the cost of counsel or representation. The arbitrators will have no authority to award punitive, consequential, or other damages not measured by the prevailing party’s actual damages except as required by statute. Any award in an arbitration initiated under this clause shall be limited to monetary damages and shall include no injunction or direction to any party other than the direction to pay a monetary amount.</p>
                        <h4>COPYRIGHT INFRINGEMENT/DMCA POLICY:</h4>
                        <p>We respect the intellectual property rights of others. Suppose you are a copyright owner or an agent thereof and believe that any Content or any material on the service infringes upon your copyrights. In that case, you may submit a notification according to the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright Agent with the following information in writing (see 17 USC §512(c)(3) for further detail): We comply with The Digital Millennium Copyright Act, 1998(DMCA). We will remove infringing materials under the Digital Millennium Copyright Act (“DMCA”) if you properly notify us that any content published on our website or through our user or us infringes copyright. If you believe that any content of our website infringes your copyright, please notify us in writing containing the following information</p>
                        <ul>
                            <li>a description of the copyrighted work or material or content that you claim has been infringed;</li>
                            <li>a description or link of the material that you claim to be infringing, and a description or link of where the material that you claim is infringing is located on the service, sufficient for Forember to locate the material;</li>
                            <li>your address, telephone number, and email address;</li>
                            <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; (<em>i.e., I have a good faith belief that the use of the copyrighted material I am complaining of is not authorized by the copyright owner, its agent, or the law</em>)</li>
                            <li>a statement by you that the information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner’s behalf<em>(i.e., The information in this notice is accurate and, under penalty of perjury, I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right that is allegedly infringed</em>)</li>
                            <li>an electronic or physical signature of the copyright owner or a person authorized to act on behalf of the copyright owner.</li>
                        </ul>
                        <p>Upon receiving a valid written notice, we will expeditiously respond and will conduct a proper investigation. We will remove such content from our website if we find any infringement. If any necessary info is missing from your notice, it may be rejected. You may send notice here at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink> including all the requirements stated above.</p>
                        <p><em>You can contact our Copyright Officer using our <RouterLink to="/contact-us"  onClick={() => this.onLinkClick("/contact-us")}>contact us</RouterLink> page.</em></p>
                        <h4>CANCELLATION/TERMINATION / EXPIRATION OF ACCOUNT OR SUBSCRIPTION </h4>
                        <p>You may cancel or terminate your password, account, and/or use of the Services, with or without cause, at any time by canceling your subscription or deleting your account with us. You hereby acknowledge and agree that your cancellation or termination or expiration will take effect immediately, If you cancel or terminate your account or subscription or upon expiration of your subscription.</p>
                        <p>We have sole and absolute discretion, and at any time and with or without prior notice to you, may suspend, cancel, transfer, or terminate your password, account, any site within an account, and/or use of any Services for violation of these Terms of Use. In the event of a dispute or conflict among or complaint from users of the Services about another’s right to establish, use, or maintain an account or Services. We have the sole right to determine whether or how to resolve such dispute, conflict, or complaint, with or without factual or other investigation.</p>
                        <p>Upon expiration, or cancellation or termination by either party, of your account, your password, and/or use of any services, you will have no right to any Material or Information you submitted, and we may temporarily or permanently block access to, remove, deactivate, delete, and discard all such Material or Information. Forember.com accepts no liability for removed or deleted Material or Information. In addition, any contracts (verbal, written, or assumed) with respect to your account, Material, or Information you Submit and/or use of any Services will be terminated at Forember’s discretion. You agree that we shall not be liable to you or any third party for any termination of your access to any Services. Upon suspension, cancellation, or termination of your account or your use of our services Service (for whatever reason), there shall be no refund of the money you paid to us.</p>
                        <h4>MISCELLANEOUS</h4>
                        <p>These miscellaneous provisions are part of just about every online terms of use to ensure its enforceability. You are agreed that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of the terms of use. We have the absolute discretion to transfer or assign all or any part of our rights under these terms of use. We shall have the right to delegate or use third-party contractors to fulfill our duties and obligations under these terms of use in connection with using our website and its services.</p>
                        <h4>INDEMNIFICATION</h4>
                        <p>You agree to defend, indemnify, and hold the Forember and/or its officers, directors, employees, agents, licensors, and suppliers harmless from and against any claims, actions or demands, liabilities, and settlements, including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your use of our website and its services and/or violation of any terms of these terms of use. As a user or member, you also agree to indemnify and hold each other harmless from any damages, costs, and expenses. We reserve the right to assume the exclusive defense and control of any demand, claim, or action arising here under or in connection with the website and all negotiations for settlement or compromise. As requested, you agree to fully cooperate with us in defense of any such demand, claim, action, and settlement or compromise negotiations.</p>
                        <h4>SEVERABILITY</h4>
                        <p>If any provision of these Terms is held invalid or unenforceable by a court, the remaining provisions will remain in effect.</p>
                        <h4>CHANGE OR MODIFICATION </h4>
                        <p>From time to time, with the needs of any necessary circumstances, these terms of use can be edited, modified, or changed without prior notice. Regularly using our website and its services will indicate your consent to such changes. We reserve the right to change these terms of use at any time without notice by posting changes on the website or by updating the website to incorporate the new terms of use. Upon making any material changes to these terms and conditions, we will notify you about such changes or modifications. You are responsible for regularly reviewing online information to obtain timely notice of such changes. Your regular use of our website and its services and its services will indicate your consent to such changes.</p>
                        <h4>HEADINGS:</h4>
                        <p>The headings in these terms of use documents are for reference only and shall not affect the interpretation of these terms of use documents.</p>
                        <h4>ENTIRE AGREEMENT</h4>
                        <p>The failure of us to exercise or enforce any right or provision of these terms of use shall not constitute a waiver of such right or provision.&nbsp;These terms of use constitute the sole and entire agreement between you and us regarding your use of our website and its services and supersede all prior and contemporaneous understandings, agreements, and warranties between you and us regarding the use of our website and its services.</p>
                        <h4>ACCEPTANCE </h4>
                        <p>Using our website and its services signifies your acceptance of these terms of use and our privacy policy. Your continued use of our website and its services will be deemed to be your acceptance of any changes to these terms of use.</p>
                        <h4>CONTACT US</h4>
                        <p>We have sole jurisdiction to provide an explanation of these terms and conditions. If you have any questions about these terms and conditions or would like to access or modify your personally identifiable information, please contact us by using the <RouterLink to="/contact-us"  onClick={() => this.onLinkClick("/contact-us")}>contact us</RouterLink> page or directly email us at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink></p>
                        <h4>FYI: Please note that</h4>
                        <ul>
                            <li>These terms of use were last updated on March 2023.</li>
                            <li>We reserve the right to change these terms of use at any time. Any material changes to this privacy policy can be made without prior notification.</li>
                            <li>Continued use of our website signifies your acceptance of any changes to these terms of use.</li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
};

export default Terms;
