import React, { Component } from "react";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import RouterComponent from "./components/Router";
import { helper } from "../assets/js/utils/Element";
import AuthApi from '../assets/js/utils/Auth';
import Config from "../../Config";
import { mapStateToProps } from "../store/reducers/rootReducer";
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Progressbar from "./components/Progressbar";

import "../assets/scss/App.scss";

const history = createBrowserHistory();

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
        this.loading = this.props.loading;
        this.loaded = false;
    }
    componentDidMount() {
        this.loaded = true;
        const { dispatch } = this.props;

        const that = this;
        this.listenHistory();
        const hasAccessToken = AuthApi.hasAccessToken();

        if(hasAccessToken === false){
            this.loading = false
            dispatch({type: 'UPDATE_STATE', state: {}}); 
        }

        AuthApi.validateToken().then((isValidToken) => {
            this.loading = false;
            if(hasAccessToken === true && isValidToken === false){
                that.logout();
            }else if(hasAccessToken === true){                
                dispatch({
                    type: "LOGIN",
                    state: {
                        authorized: true,
                        user: isValidToken
                    }
                });
            }
        }).catch(err => {
            this.loading = false;
            dispatch({type: 'UPDATE_STATE',state: {}});
            console.log(err);
        });

        if(process.env.REACT_APP_ENV !== "production"){
            // console.log(process.env);
        }

        let returnUrl = helper.getParam("rte");
        if (returnUrl && returnUrl.length > 0 && returnUrl !== "null") {
            returnUrl = window.location.search.replace("?rte=", "");
            dispatch({ type: "UPDATE_STATE", state: { url: "/" + returnUrl } });
            history.push(returnUrl);
        }
    }
    logout(){
        // history.push("/logout");
        AuthApi.logout();
        this.props.dispatch({type: 'LOGOUT'});
    }
    listenHistory() {        
        const that = this;
        history.listen(function (update) {
            that.props.dispatch({type: 'UPDATE_STATE',state: {url: update.location.pathname }});           
        });
    }
    getHeadScripts() {
        let headScripts = [];
        headScripts.push({
            type: 'text/javascript',
            async: "async",
            defer: true,
            src: "https://www.google.com/recaptcha/api.js?onload=&render=explicit"
        });

        return headScripts;
    }
    loadSettings(){
        
    }
    render() {
        const { mainLoading, authorized, url, hugs } = this.props.state;
        if (process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true") {
            // console.log(this.props.state);
        }
        if (mainLoading) {
            return null;
        }

        const isTransparentHeader = helper.isTransparentHeader(url);
        const bodyClass = helper.getBodyClass(url);
        const headScripts = this.getHeadScripts();

        if (this.loading === true || this.state.loading) {
            return null;
        }
        return (
            <BrowserRouter history={history}>
                <Helmet
                    htmlAttributes={{
                        class: bodyClass,
                    }}
                    script={headScripts}
                    title={Config.getAppTitle()}
                    keywords={Config.getKeywords()}
                />
                <div
                    className={
                        bodyClass +
                        " App" +
                        (authorized ? " user_authorized" : " user_unauthorized") +
                        (isTransparentHeader ? " app_transparent_header" : "")
                    }
                >
                    {
                        url !== "/login" && url !== "/signup" && url !== "/forgot" ?
                            <Navbar isTransparentHeader={true} history={history} />
                        :
                            null
                    }
                    <div className="main-container" id="global_wrapper">
                        <RouterComponent history={history} />
                    </div>
                    {
                        url !== "/login" && url !== "/signup" && url !== "/forgot" ?
                            <Footer  history={history} />
                        :
                            null
                    }
                    {
                        hugs.length > 0 ?
                            <Progressbar hugs={hugs} history={history} generate={true} />
                        :
                            <></>
                    }
                </div>
            </BrowserRouter>
        );
    }
}

App.defaultProps = {
    loading: true
}
App.propTypes = {
    loading: PropTypes.bool,
}

export default connect(mapStateToProps)(App);
